import { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import "./index.css";
import Pagination from "react-bootstrap/Pagination";
import { useQuery } from "@tanstack/react-query";
import { adminAxiosInstance } from "../../admin/plugins/axios";
import { AppContext } from "../../scripts/context";
import { Spinner } from "react-bootstrap";
import { getGiveAwayList } from "../../Helper/firebase";
import { convertToCSV } from "../../Helper/Helper";

function MyPagination({ currentPage, transPages, setTransactionsPagination }) {
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setTransactionsPagination(pageNumber);
  };

  // Generate pagination items
  let items = [];
  for (let number = 1; number <= transPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>,
    );
  }

  return (
    <div>
      <Pagination>{items}</Pagination>
    </div>
  );
}

function Admin() {
  const { userData } = useContext(AppContext);

  const [transactionsPagination, setTeansactionsPagination] = useState(1);
  const [commessionsPagination, setCommessionsPagination] = useState(1);

  const { data: prizesData } = useQuery({
    queryKey: ["prizes"],
    queryFn: getGiveAwayList,
  });

  const { data: tokenDecimalData } = useQuery({
    queryKey: ["token decimals"],
    queryFn: () => adminAxiosInstance.get("tokenDecimals"),
  });

  const { data: payableAdressData } = useQuery({
    queryKey: ["payable Address"],
    queryFn: () => adminAxiosInstance.get("payableAddress"),
  });

  const { data: startingDateData } = useQuery({
    queryKey: ["starting date"],
    queryFn: () => adminAxiosInstance.get("startingDate"),
  });

  const { data: deadlineDateData } = useQuery({
    queryKey: ["deadline date"],
    queryFn: () => adminAxiosInstance.get("deadlineDate"),
  });

  const { data: totalPresaleTokenAmountData } = useQuery({
    queryKey: ["total Presale Token Amount"],
    queryFn: () => adminAxiosInstance.get("totalPresaleTokenAmount"),
  });

  const { data: totalPurchasedTokenAmountData } = useQuery({
    queryKey: ["total Purchased Token Amount"],
    queryFn: () => adminAxiosInstance.get("totalPurchasedTokenAmount"),
  });

  const { data: totalCommissionAmountData } = useQuery({
    queryKey: ["total Commission Amount"],
    queryFn: () => adminAxiosInstance.get("totalCommission"),
  });

  const { data: purchasedTransactionsData, isLoading: loadingTranasctions } = useQuery({
    queryKey: ["admin transactions", transactionsPagination],
    queryFn: () =>
      adminAxiosInstance.get("purchasedTransactions", {
        params: { pageNo: transactionsPagination, perPage: 5 },
      }),
  });

  const totalTransactionsList = Math.ceil(purchasedTransactionsData?.data.totalList / 5) || 1;

  const transactions = purchasedTransactionsData?.data.transactions ?? [];

  const { data: commissionsData, isLoading: loadingCommissions } = useQuery({
    queryKey: ["admin commissions", commessionsPagination],
    queryFn: () =>
      adminAxiosInstance.get("commissions", {
        params: { pageNo: commessionsPagination, perPage: 5 },
      }),
  });

  const totalCommessionsList = Math.ceil(commissionsData?.data.totalList / 5) || 1;

  const commessions = commissionsData?.data.commissions ?? [];

  const downloadCSVOfPrizes = () => {
    const csv = convertToCSV(prizesData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "prizes.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Container fluid className='bg-hero'>
        <Header />
      </Container>
      <Container>
        <div className='d-flex justify-content-between align-items-center mt-5'>
          <div>
            <h1 className=' ms-4'>Welcome Owner {userData?.name}</h1>
            <p className='ms-4'>Here you can view your product's info.</p>
          </div>
          <button type='button' class='btn btn-primary me-4' onClick={downloadCSVOfPrizes}>
            Download Prizes Data (CSV)
          </button>
        </div>
        <div className='item d-flex align-items-center'>
          <div className='text-of-slider'>
            <h2>Address:</h2>
            <span className='text-wrap'>{payableAdressData?.data.address}</span>

            <p>Address where presale money goes</p>
          </div>
        </div>

        <div className='d-flex align-items-center md-wrap'>
          <div className='item d-flex align-items-center w-50 w-md-100'>
            <div className='text-of-slider'>
              <h2>Starting:</h2>
              <span>
                {new Date(Number(startingDateData?.data.startingDate) * 1000).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false,
                  },
                )}
              </span>

              <p>When the presale starts</p>
            </div>
          </div>
          <div className='item d-flex align-items-center w-50 w-md-100'>
            <div className='text-of-slider'>
              <h2>Ending:</h2>
              <span>
                {new Date(Number(deadlineDateData?.data.deadlineDate) * 1000).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false,
                  },
                )}
              </span>
              <p>When the presale ends</p>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center md-wrap'>
          <div className='item d-flex align-items-center w-50 w-md-100'>
            <div className='text-of-slider'>
              <h2>Total Commission Amount:</h2>
              <span>{totalCommissionAmountData?.data.totalCommission / 10 ** 18 ?? "--"}</span>
              <p>Total commission users got</p>
            </div>
          </div>
          <div className='item d-flex align-items-center w-50 w-md-100'>
            <div className='text-of-slider'>
              <h2>Purchased Amount:</h2>
              <span>
                {" "}
                {(
                  totalPurchasedTokenAmountData?.data.totalPurchasedTokenAmount /
                  10 ** tokenDecimalData?.data.decimals
                ).toLocaleString()}{" "}
                AIT
              </span>{" "}
              <span>
                Out of{" "}
                {(
                  totalPresaleTokenAmountData?.data.totalPresaleTokenAmount /
                  10 ** tokenDecimalData?.data.decimals
                ).toLocaleString()}{" "}
                AIT
              </span>
              <p>Total purchased AIT in presale</p>
            </div>
          </div>
        </div>
        <div className='item d-flex align-items-center'>
          <div className='text-of-slider w-100'>
            <h2 className='mt-3'>Transactions:</h2>
            {loadingTranasctions ? (
              <Spinner animation='border' role='status' className='my-5' />
            ) : (
              <div className='scrollx-auto w-100'>
                <table className='table admin-table'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>User Name</th>
                      <th>User Address</th>
                      <th>Amount</th>
                      <th>Purchase Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.user.name ?? item.user.user_alice}</td>
                        <td>{item.user.wallet_address}</td>
                        <td>{item.amount}</td>
                        <td>{item.purchaseDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <MyPagination
                  currentPage={transactionsPagination}
                  transPages={totalTransactionsList}
                  setTransactionsPagination={setTeansactionsPagination}
                />
              </div>
            )}
          </div>
        </div>

        <div className='item d-flex align-items-center'>
          <div className='text-of-slider w-100 '>
            <h2 className='mt-3'>Affiliations:</h2>
            {loadingCommissions ? (
              <Spinner animation='border' role='status' className='my-5' />
            ) : (
              <div className='scrollx-auto w-100'>
                <table className='table admin-table'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Amount</th>
                      <th>Purchase Date</th>
                      <th>User Name</th>
                      <th>User Address</th>
                      <th>Sponsor Name</th>
                      <th>Sponsor Address</th>
                      <th>Relation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {commessions.map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.amount}</td>
                        <td>{item.purchaseDate}</td>
                        <td>{item.user.name ?? item.user.user_alice}</td>
                        <td>{item.user.wallet_address}</td>
                        <td>{item.sponsor.name ?? item.sponsor.user_alice}</td>
                        <td>{item.sponsor.wallet_address}</td>
                        <td>{item.relation}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <MyPagination
                  currentPage={commessionsPagination}
                  transPages={totalCommessionsList}
                  setTransactionsPagination={setCommessionsPagination}
                />
              </div>
            )}
          </div>
        </div>
      </Container>

      <Container fluid>
        <Footer />
      </Container>
    </>
  );
}

export default Admin;
