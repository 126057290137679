import { Paper, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledHeadCell = styled(TableCell)(({ theme }) => ({
  color: "grey",
  ...theme.typography.body5,
}));
export const StyledDataCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body4,
}));

export const StyledTablePaper = styled(Paper)(() => ({
  borderRadius: "1rem",
  background: "#3B3B3B",
  boxShadow: "none",
}));

export const StyledTableRow = styled(TableRow)(() => ({
  "&:last-child td, &:last-child th": { border: 0 },
}));

export const StyledPrice = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  "& :first-of-type": {
    marginRight: "0.55rem",
  },
}));
