import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import schemaLogin from "../../Auth/Login/schemaLogin";
import { adminAxiosInstance } from "../../plugins/axios";
import { auth } from "../../../configs/firebase.config";
import { signInWithEmailAndPassword } from "firebase/auth";

export const useForm = (args = {}) => {
  /**
   * initialize the form
   */
  const [form, setForm] = useState(args);
  /**
   * validator object which contains the error messages author Khaled Mofeed
   */
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const setValue = (event, field = null) => {
    const name = field ?? event?.target.name;
    const value = event?.target.value;
    try {
      setForm({ ...form, [name]: value });
    } catch (e) {}
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    setErrors({ email: false, password: false });
    schemaLogin
      .validate(form, { abortEarly: false })
      .then(() => {
        setLoading(true);
        signInWithEmailAndPassword(auth, form.email, form.password)
          .then(async (data) => {
            console.log("im here", data);
            localStorage.setItem("admin-token", data._tokenResponse.idToken);
            adminAxiosInstance.defaults.headers[
              "authorization"
            ] = `Bearer ${data._tokenResponse.idToken}`;
            toast.success("You have successfully logged in");
            navigate("/back-office");
          })
          .catch((error) => {
            console.log("fb error", error);
            toast.error(error.code);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        if (!error.inner) {
          console.log("err", error);
          return;
        }
        setErrors(
          error.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
          }, {}),
        );
      });
  };

  return {
    form,
    handleLoginSubmit,
    setForm,
    setValue,
    setLoading,
    loading,
    errors,
    setErrors,
  };
};
