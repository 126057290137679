/**
 * use yup schema to validator object which contains the error messages
 * author Khaled Mofeed
 */
import * as yup from "yup";
export default yup.object().shape({
  // name: yup.string().required("Name is required"),
  // bio: yup.string().min(20).max(1000).required(),
  // custom_url: yup.string().required("Custom url is required"),
  // twitter: yup.string().required(),
  // facebook: yup.string().required(),
  // portfolio: yup.string().required(),
  // linkedin: yup.string().required(),
  // instagram: yup.string().required(),
});
