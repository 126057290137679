/*
 * ethers library is used to interact with the smart contract
 * @see https://docs.ethers.io/v5/
 * and the JSON file is used to store the ABI of the smart contract
 * @see https://docs.ethers.io/v5/api/utils/abi/
 */
import { ethers } from "ethers";
import abi from "../../../configs/abis/presale-contract.json";
import config from "../../../configs";

const contractAddress = config.get("smartContract.CONTRACT_ADDRESS");

// ^ Helper functions

// create a contract instance
export const makeContract = (signer) => {
  // await window.ethereum.request({ method: "eth_requestAccounts" });
  const contract = new ethers.Contract(contractAddress, abi, signer);
  return contract;
};

// this function is to get token decimals
const getTokenDecimals = async (signer) => {
  const contract = await makeContract(signer);
  const decimals = await contract.getTokenDecimals();
  return decimals;
};

// this function is used to get a single value of the smart contract promises
const singleValueFunc = async (func, signer) => {
  const contract = makeContract(signer);
  const value = await contract[func]();
  // const parsedValue = await valueParser(value);
  // Convert to number and format it as desired
  // return parseFloat(parsedValue).toFixed(10); // Assuming 8 decimal places

  return await valueParser(value, signer);
};

// this function is used to parse a value and divide it by 10 ^ token decimals
const valueParser = async (value, signer) => {
  const decimals = await getTokenDecimals(signer);
  return parseInt(value) / 10 ** parseInt(decimals);
};

// this a generic function to handle the tables' data
const tableData = async (func, args, signer) => {
  const deployedPresaleContract = await makeContract(signer);
  const { totalList_, ...rowData } = await deployedPresaleContract[func](
    ...args
  );
  const dataArr = rowData.transactions_ || rowData.commissions_;
  const transactions = await Promise.all(
    dataArr.map(async (item, index) => ({
      id: `${index}-${item.purchaseDate}`,
      amount: parseInt(item.amount) / 10 ** 16,
      date: new Date(item.purchaseDate * 1000).toLocaleDateString(),
      relation: parseInt(item.relation),
      address: item.userAddress,
      commission: parseInt(item.amount) / 10 ** 18,
      commissionRate: parseInt(item.commissionRate) ,
    }))
  );
  const totalList = parseInt(totalList_);
  return { transactions, totalList };
};

// ^ Contract functions

// this function is used to get the current user's address
export const getAddress = async (signer) => {
  // const signer = provider.getSigner();
  const address = await signer.getAddress();
  return address;
};

// this function is used to get the current user's balance
export const getBalance = async (signer, provider) => {
  const address = await getAddress(signer);
  const balance = await provider.getBalance(address);
  return ethers.utils.formatEther(balance);
};

// this function is used to get the presale starting date
export const getStartingDate = async (signer) => {
  const deployedPresaleContract = await makeContract(signer);
  return deployedPresaleContract.getStartingDate();
};

// this function is used to get the presale ending date
export const getDeadlineDate = async (signer) => {
  const deployedPresaleContract = await makeContract(signer);
  return deployedPresaleContract.getDeadlineDate();
};

// this function is used to get the user's total commission list
export const getUserCommissionsList = async (page, limit, signer) => {
  const args = [page, limit];
  const data = await tableData("getUserCommissionsList", args, signer);
  return data;
};

// this function is used to get the user's total transaction list
export const getUserPurchasedTransactionsList = async (page, limit, signer) => {
  const args = [page, limit];
  const data = await tableData(
    "getUserPurchasedTransactionsList",
    args,
    signer
  );
  return data;
};

// this function is used to get the minimum an maximum amount of tokens that can be purchased
export const getMinMaxAllocation = async (signer) => {
  const deployedPresaleContract = makeContract(signer);
  const minMaxAllocation = await deployedPresaleContract.getMinMaxAllocation();
  return {
    minAllocation: await valueParser(minMaxAllocation.minAllocation_, signer),
    maxAllocation: await valueParser(minMaxAllocation.maxAllocation_, signer),
  };
};

// this function is used to purchase tokens
export const purchaseAiT = async (telv, sponsor, i3, signer) => {
  const deployedPresaleContract = await makeContract(signer);
  await deployedPresaleContract.purchase(telv, sponsor, i3);
};

// get AITellit exchange rate
export const getTELVExchangeRate = async (signer) => {
  const contract = makeContract(signer);
  const value = await contract["getTokenExchangeRate"]();

  return await valueParser(value, signer);
};
// get whole AITellit token
export const getTotalPrice = async (signer) => {
  const contract = makeContract(signer);
  const value = await contract["getTotalPrice"]("10000000000000000");
  return parseInt(value);
};
// get whole AITellit token
export const getTotalPresaleTELVAmount = async (signer) => {
  const contract = makeContract(signer);
  const value = await contract["getTotalPresaleTokenAmount"]();

  return await valueParser(value, signer);
};

// get total amount of tokens purchased for a user
export const getUserTotalPurchasedAmount = async (signer) => {
  const contract = makeContract(signer);
  const value = await contract["getUserTotalPurchasedAmount"]();

  return await valueParser(value, signer);
};

// get the rate of recieved tokens when an affiliate sponsor is used
export const getCommissionRate = singleValueFunc.bind(
  null,
  "getCommissionRate"
);

// get total amount of tokens purchased
export const getTotalPurchasedTELVAmount = async (signer) => {
  const contract = makeContract(signer);
  const value = await contract["getTotalPurchasedTokenAmount"]();

  return await valueParser(value, signer);
};

export const getUserPresaleData = async (signer) => {
  const contract = makeContract(signer);
  const value = await contract["getUserPresaleData"]();

  return await value;
};
// ^ Figure out in which phase the presale is
export const whenAreWeNow = async () => {
  const deployedPresaleContract = await makeContract();
  if (!deployedPresaleContract) return;
  const startingDate = await deployedPresaleContract.getStartingDate();
  const deadlineDate = await deployedPresaleContract.getDeadlineDate();
  const now = Math.floor(Date.now() / 1000);
  if (now < startingDate) {
    return "before";
  }
  if (now > deadlineDate) {
    return "after";
  }
  return "live";
};

export const getGasFees = async (purchaseToken) => {
  const deployedPresaleContract = await makeContract();
  const price = await deployedPresaleContract.getTotalPrice();
  const gasFees = deployedPresaleContract.estimateGas.purchase(
    "0x" + Number(purchaseToken * 10 ** 16).toString(16),
    "0x0000000000000000000000000000000000000000",
    { value: purchaseToken * price }
  );
  return gasFees;
};
