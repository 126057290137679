import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./lib/font-awesome/css/all.min.css";
import "@rainbow-me/rainbowkit/styles.css";

import React from "react";
import ToasterCard from "./plugins/Toaster";
import AppRoute from "./plugins/router";

import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./configs/queryClient";

import { WagmiProvider } from "wagmi";
import {
  RainbowKitProvider,
  getDefaultConfig,
  darkTheme,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import { bsc, bscTestnet } from "wagmi/chains";
import {
  trustWallet,
  coinbaseWallet,
  metaMaskWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";

// const wallets = [
//   {
//     groupName: "Recommended",
//     wallets: [
//       trustWallet({ chains: [bsc, bscTestnet] }),
//       coinbaseWallet({ chains: [bsc, bscTestnet] }),
//       metaMaskWallet({ chains: [bsc, bscTestnet] }),
//       walletConnectWallet({ chains: [bsc, bscTestnet] }),
//     ],
//   },
//   {
//     appName: "aitellit",
//     projectId: "948bc15a7b5888e5beae17fde670f26a",
//   },
// ];

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [
        () => trustWallet({ projectId: "948bc15a7b5888e5beae17fde670f26a" }),
        () => coinbaseWallet({ projectId: "948bc15a7b5888e5beae17fde670f26a" }),
        () => metaMaskWallet({ projectId: "948bc15a7b5888e5beae17fde670f26a" }),
        () => walletConnectWallet({ projectId: "948bc15a7b5888e5beae17fde670f26a" }),
      ],
    },
  ],
  {
    projectId: "948bc15a7b5888e5beae17fde670f26a",
    appName: "aitellit",
  },
);

const config = getDefaultConfig({
  appName: "aitellit",
  projectId: "948bc15a7b5888e5beae17fde670f26a",
  chains: [bsc],
  connectors,
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiProvider config={config}>
        <RainbowKitProvider
          theme={darkTheme({
            accentColor: "#f8780c",
            accentColorForeground: "white",
            borderRadius: "large",
            fontStack: "system",
            overlayBlur: "small",
          })}
          coolMode
        >
          <ToasterCard />
          <AppRoute />
        </RainbowKitProvider>
      </WagmiProvider>
    </QueryClientProvider>
  );
}

export default App;
