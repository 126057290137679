/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import { ethers, utils } from "ethers";
import { useAccount, useReadContract } from "wagmi";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useCallback, useMemo } from "react";
import ConnectWalletModal from "../pages/Layout/ConnectWalletModal";
import { GetLocalStorag, SaveLocalStorag } from "../Helper/Helper";
import config from "../configs";
import tokenAbi from "../configs/abis/token.json";
import { toast } from "react-hot-toast";
import { request } from "../admin/plugins/axios";
import { endpoints } from "../utils/networks";
import { useEthersSigner } from "../hooks/useEthersSigner";
import {
  useGetBalance,
  useGetContractOwner,
  useGetDeadlineDate,
  useGetStartingDate,
} from "../pages/presale/presaleContractFunctions/wagmi";
import vestingAbi from "../configs/abis/vesting-contract.json";

const AppContext = createContext(null);
const { ethereum } = window;

const chainId = config.get("smartContract.CHAIN_ID");
const chainRpcUrl = config.get("smartContract.CHAIN_RPC_URL");
const chainName = config.get("smartContract.CHAIN_NAME");
const chainCurrencySymbol = config.get("smartContract.CHAIN_CURRENCY_SYMBOL");

const chainBlockExplorerUrl = config.get("smartContract.CHAIN_BLOCK_EXPLORER_URL");

const tokenAddress = config.get("smartContract.TOKEN_ADDRESS");

const vestingContract = {
  address: config.get("smartContract.VESTING_ADDRESS"),
  abi: vestingAbi,
};

const AppContextProvider = ({ children, theme }) => {
  const initState = {
    user: {
      address: null,
      balance: "0.0000",
      name: "",
      bio: "",
      avatar: "",
      cover: "",
      custom_url: "",
      twitter: "",
      facebook: "",
      portfolio: "",
      linkedin: "",
      instagram: "",
      following_count: "",
      followers_count: "",
    },
    follow: {
      followings: [],
      followers: [],
    },
    event: [],
    eventFillter: [],
    eventCategories: [],
    allNftFillter: [],
    Nft_A_Section: [],
    Nft_B_Section: [],
    Nft_C_Section: [],
    Nft_D_Section: [],
    Nft_E_Section: [],
    Nft_F_Section: [],
    Nft_G_Section: [],
    ownerOfNft: [],
    loading: true,
    provider: null,
    netWork: null,
    wrongNetwork: false,
    contracts: {
      token: {
        contract: null,
      },
      nft: {
        contract: null,
        data: {
          time: "",
          items: [],
          seletedNFT: null,
          lockedNFT: null,
          boostingPercentage: 0,
        },
        forceClass: "",
      },
      presale: {
        contract: null,
        data: {
          time: "",
          list: [],
          transactionsList: [],
          stakedDetails: {
            lockedLPs: "0.0000",
            totalLPs: "0.0000",
            nextUlocke: "00:00:00",
            unlockedLPs: "0.0000",
            unlocekdRewards: "0.0000",
            nftUnlockedDate: "N / A",
          },
          stakingRewards: "0.0000",
          stakeAgain: false,
        },
      },
      marketplace: {
        contract: null,
      },
      nftMarketplace: {
        contract: null,
      },
      revenue: {
        contract: null,
      },
    },
    connectWallet: () => setConnectWalletModal(true),
  };
  const [userData, setUserData] = useState(initState.user);
  const [userDataSponsor, setUserDataSponsor] = useState();
  const [followData, setFollowData] = useState(initState.follow);
  const [event, setEvent] = useState(initState.event);
  /*  This Status For Fillter Events| khaled Mofeed | 13/02/2023 */
  const [eventFillter, setEventFillter] = useState(initState.eventFillter);
  const [pagination_options, setPagination_options] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  /*  This Status For Fillter Events by Categories| khaled Mofeed | 14/02/2023 */
  const [eventCategories, setEventCategories] = useState(initState.eventCategories);
  const [location, setLocation] = useState([]);
  const [CategoriesId, setCategoriesId] = useState([]);
  const [date_From, setDate_From] = useState();
  const [date_To, setDate_To] = useState();
  const [keyword, setKeyword] = useState("");
  const [history, setHistory] = useState("0");
  const [owned, setOwned] = useState();
  /*  This Status For Fillter NFT| khaled Mofeed | 13/02/2023 */
  const [allNftFillter, setAllNftFillter] = useState(initState.allNftFillter);
  const [Nft_A_Section, setNft_A_Section] = useState(initState.Nft_A_Section);
  const [Nft_B_Section, setNft_B_Section] = useState(initState.Nft_B_Section);
  const [Nft_C_Section, setNft_C_Section] = useState(initState.Nft_C_Section);
  const [Nft_D_Section, setNft_D_Section] = useState(initState.Nft_D_Section);
  const [Nft_E_Section, setNft_E_Section] = useState(initState.Nft_E_Section);
  const [Nft_F_Section, setNft_F_Section] = useState(initState.Nft_F_Section);
  const [Nft_G_Section, setNft_G_Section] = useState(initState.Nft_G_Section);

  const [pagination_Options, setPagination_Options] = useState(0);
  const [per_Page, setPer_Page] = useState(10);
  const [inPage, setInPage] = useState(1);
  const [fillterKeyword, setFillterKeyword] = useState("");
  const [price_from, setPrice_from] = useState();
  const [price_to, setPrice_to] = useState();
  const [statusNft, setStatusNft] = useState();
  const [condition, setCondition] = useState();
  const [CategoriesIdNft, setCategoriesIdNft] = useState([]);
  /*  This Status For Owner of NFT of user profile | khaled Mofeed | 14/02/2023 */
  const [ownerOfNft, setOwnerOfNft] = useState(initState.eventCategories);
  const [pagination_OptionsOwn, setPagination_OptionsOwn] = useState(0);
  const [per_PageOwn, setPer_PageOwn] = useState(10);
  const [inPageOwn, setInPageOwn] = useState(1);
  const [isNetwork, setIsNetwork] = useState(true);
  const [state, setStates] = useState(initState);
  const setState = (newState) => setStates((prevState) => ({ ...prevState, ...newState }));
  const [nftItems, setNftItems] = useState([]);
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  /*  This Status For Update Network Modal | khaled Mofeed | 4/03/2023 */
  const [showModalUpdateNetwork, setShowModalUpdateNetwork] = useState(false);
  const handleClose = () => setShowModalUpdateNetwork(false);
  const handleShow = () => setShowModalUpdateNetwork(true);

  const signer = useEthersSigner();

  const [TOKEN_User, setTOKEN_User] = useState(localStorage.getItem("UserToken"));
  const [isToken, setIsToken] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isOwner, setIsOwner] = useState(false);
  const [ownerLoading, setOwnerLoading] = useState(true);

  const [reCallTime, setReCallTime] = useState(false);
  const { data: balance, isError } = useGetBalance();
  const { data: owners, error } = useGetContractOwner();
  const { parseValue: startingDate } = useGetStartingDate();
  const { parseValue: deadlineDate } = useGetDeadlineDate();
  const { data: vestingStartingDateBigInt } = useReadContract({
    ...vestingContract,
    functionName: "getStartingDate",
  });
  const { address, chain, connector: activeConnector } = useAccount();

  const vestingStartingDate = parseInt(vestingStartingDateBigInt) * 1000;
  /*  This function use for  Get Web3 | khaled Mofeed | 5/12/2022 */
  const getWeb3 = async (account, walletConnectProvider) => {
    if (ethereum || walletConnectProvider) {
      try {
        const provider = new ethers.providers.Web3Provider(
          walletConnectProvider || ethereum,
          "any",
        );

        let address = account || ethereum.selectedAddress;
        if (!address) {
          const addressRequest = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          address = addressRequest[0];
        }

        let netWork = chain;
        if (chain.id !== Number(chainId)) {
          setIsNetwork(false);
          SaveLocalStorag("IsNetwork", false);
          await updateNetWork();
          netWork = chain;
        } else {
          setIsNetwork(true);
          SaveLocalStorag("IsNetwork", true);
        }
        const balanceSelectedAddress = balance?.formatted;

        setState({
          provider,
          netWork,
          user: {
            ...state.user,
            address,
            balance: String(utils.formatUnits(balanceSelectedAddress)).match(
              /^-?\d+(?:\.\d{0,4})?/,
            )?.[0],
          },
          loading: false,
          contracts: {
            ...state.contracts,
            presale: {
              ...state.contracts.presale,
            },
          },
        });
      } catch (error) {
        const netWork = chain;
        setState({
          loading: false,
          netWork,
        });
      }
    }
  };
  /*  This function use for  Update NetWork | khaled Mofeed | 5/12/2022 */
  const updateNetWork = async () => {
    if (!ethereum) {
      toast.error("Metamask is not installed, please install it to complete the process");
      return;
    }

    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${Number(chainId).toString(16)}`,
          },
        ],
      });
      setIsNetwork(true);
      SaveLocalStorag("IsNetwork", true);

      // handleClose();
    } catch (error) {
      // console.log("errorerror|||", error);
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: chainName,
              chainId: `0x${Number(chainId).toString(16)}`,
              rpcUrls: [chainRpcUrl],
              nativeCurrency: {
                name: chainCurrencySymbol,
                symbol: chainCurrencySymbol,
                decimals: 18,
              },
              blockExplorerUrls: [chainBlockExplorerUrl],
            },
          ],
        });
        setIsNetwork(false);
        SaveLocalStorag("IsNetwork", false);
      } catch (error) {
        toast.custom(
          <div className='switchFaild'>
            <span>
              {" "}
              <img src='/img/metamask.png' alt='metamask' />
            </span>
            <div>
              <h6>Network switch faild</h6>
              <p>
                Please switch network manually to <br /> Smart Chain - Testnet{" "}
              </p>
            </div>
          </div>,
        );
        // setIsNetwork(true);
      }
    }
  };
  /*  This function use for  Dis Connect Wallet | khaled Mofeed | 5/12/2022 */
  const disconnect = async () => {
    // if (state.user.address) {
    localStorage.removeItem("connectStatus");
    localStorage.removeItem("UserToken");
    setIsToken(false);
    setState({
      ...initState,
      user: {
        address: null,
        balance: "0.0000",
      },
      loading: false,
    });
    setUserDataSponsor();
  };

  useEffect(() => {
    if (!ethereum) {
      toast.error("Metamask is not installed, please install it to complete the process");
    } else {
      const handleAccountsChanged = async (accounts) => {
        if (!accounts[0]) {
          setState({
            ...initState,
            user: {
              address: "",
              balance: "0.0000",
            },
            loading: false,
          });
        } else {
          setState({ loading: true });
          await getWeb3(accounts[0]);
        }
      };

      const handleChainChanged = async (chainId) => {
        if (parseInt(chainId, 16) !== Number(config.get("smartContract.CHAIN_ID"))) {
          setIsNetwork(false);
          SaveLocalStorag("IsNetwork", false);
          setState({
            loading: false,
            netWork: {
              ...state.netWork,
              chainId,
            },
          });
        } else {
          setIsNetwork(true);
          SaveLocalStorag("IsNetwork", true);
          if (state.user.address) {
            setState({
              loading: true,
              netWork: {
                ...state.netWork,
                chainId,
              },
            });
            SaveLocalStorag("IsNetwork", true);
            await getWeb3();
          }
        }
      };

      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("disconnect", () => {
        setState({
          ...initState,
          user: {
            address: "",
            balance: "0.0000",
          },
          loading: false,
        });
      });

      return () => {
        ethereum.removeListener("accountsChanged", handleAccountsChanged);
        ethereum.removeListener("chainChanged", handleChainChanged);
      };
    }
  }, []);

  const getConnectedStatus = useCallback(async () => {
    if (state.user.address) {
      getWeb3(state.user.address);
    } else if (GetLocalStorag("connectStatus") === "2") {
      const provider = new WalletConnectProvider({
        rpc: {
          [chainId]: chainRpcUrl,
        },
        bridge: "https://bridge.walletconnect.org",
      });
      const connected = await provider.enable();

      getWeb3(connected[0], provider);
      provider.on("accountsChanged", (accounts) => {
        if (state.user.address !== accounts[0]) {
          setState({ loading: true });
          getWeb3(accounts[0], provider);
        }
      });
    } else {
      setState({
        loading: false,
      });
    }
  }, [ethereum]);

  useEffect(() => {
    getConnectedStatus();
  }, [TOKEN_User]);

  useEffect(() => {
    const getNetwork = async () => {
      setState({
        netWork: chain,
        ...(ethereum.selectedAddress ? {} : { loading: false }),
      });
    };

    chain && getNetwork();
  }, []);

  const getPresaleTime = async () => {
    let time = "";
    try {
      const isOwner = owners.includes(state?.user?.address);
      setIsOwner(isOwner);

      const now = Math.floor(Date.now() / 1000);
      if (now < startingDate) {
        console.log("before");
        time = "before";
      } else if (now > deadlineDate) {
        console.log("after");
        time = "after";
      } else {
        console.log("live");
        time = "live";
      }
    } catch (error) {
      return { time };
    }
    return {
      time,
      // contract: presaleContract,
    };
  };

  // const getNftPresaleTime = async () => {
  //   let time = "";
  //   if (!state?.user?.address) return time;
  //   // const provider = new ethers.providers.Web3Provider(ethereum);
  //   // const signer = provider.getSigner();
  //   const nftContract = new ethers.Contract(nftAdress, nftAbi, signer);

  //   try {
  //     const startingDate = await nftContract.getStartingDate();
  //     const maxSupply = await nftContract.getMaxSupply();
  //     const purchased = await nftContract.getTotalNFTPurchased();

  //     const now = Math.floor(Date.now() / 1000);
  //     if (now < startingDate) {
  //       time = "before";
  //     } else if (maxSupply <= purchased) {
  //       time = "after";
  //     } else {
  //       time = "live";
  //     }
  //   } catch (error) {
  //     return time;
  //   }
  //   return time;
  // };

  // const getMarketplaceContract = () => {
  //   const provider = new ethers.providers.Web3Provider(ethereum);
  //   const signer = provider.getSigner();
  //   const marketplaceContract = new ethers.Contract(
  //     marketplaceAddress,
  //     marketplaceAbi,
  //     signer
  //   );
  //   return marketplaceContract;
  // };

  const getTokenContract = () => {
    // const provider = new ethers.providers.Web3Provider(ethereum);
    // const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    return tokenContract;
  };

  // const getNftMarketplaceContract = () => {
  //   // const provider = new ethers.providers.Web3Provider(ethereum);
  //   // const signer = provider.getSigner();
  //   const nftMarketplaceContract = new ethers.Contract(
  //     nftMarketplaceAddress,
  //     nftMarketplaceAbi,
  //     signer
  //   );
  //   return nftMarketplaceContract;
  // };

  // const getRevenueContract = () => {
  //   // const provider = new ethers.providers.Web3Provider(ethereum);
  //   // const signer = provider.getSigner();
  //   const revenueContract = new ethers.Contract(
  //     revenueAddress,
  //     revenueAbi,
  //     signer
  //   );
  //   return revenueContract;
  // };

  const setTimes = async () => {
    const presaleTime = await getPresaleTime();
    // const nftPresaleTime = await getNftPresaleTime();
    setOwnerLoading(false);
    // const updatedNft = {
    //   ...state.contracts.nft,
    //   data: {
    //     ...state.contracts.nft.data,
    //     time: nftPresaleTime,
    //   },
    // };
    const updatedPresale = {
      ...state.contracts.presale,
      contract: presaleTime.contract,
      data: {
        ...state.contracts.presale.data,
        time: presaleTime.time,
      },
    };
    // const updatedMarketplace = {
    //   ...state.contracts.marketplace,
    //   contract: getMarketplaceContract(),
    // };

    const updatedToken = {
      ...state.contracts.token,
      contract: getTokenContract(),
    };

    // const updatedNftMarketplace = {
    //   ...state.contracts.nftMarketplace,
    //   contract: getNftMarketplaceContract(),
    // };

    // const updatedRevenue = {
    //   ...state.contracts.revenue,
    //   contract: getRevenueContract(),
    // };

    setState({
      contracts: {
        token: updatedToken,
        // marketplace: updatedMarketplace,
        // nft: updatedNft,
        presale: updatedPresale,
        // nftMarketplace: updatedNftMarketplace,
        // revenue: updatedRevenue,
      },
    });
  };
  const getProfileDataSponsor = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const sponsor = urlParams.get("sponsor");

      if (!sponsor) {
        // If no sponsor parameter found in the URL, exit the function
        setUserDataSponsor();
        return;
      }
      // setLoading(true); // Set loading state to true
      const response = await request(
        endpoints.ProfileData.GetProfileData.method,
        `${endpoints.ProfileData.GetProfileData.url}?wallet_address=${sponsor}`,
      );

      setUserDataSponsor(response.data.data.user); // Assuming setUserDatasponsor is a state updater function
      // console.log("response------", response);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    } finally {
      // setLoading(false); // Set loading state to false, regardless of success or failure
    }
  };
  const urlParams = new URLSearchParams(window.location.search);
  const sponsor = urlParams.get("sponsor");
  useEffect(() => {
    if (sponsor) {
      getProfileDataSponsor();
    }
  }, [sponsor]);

  useEffect(() => {
    if (state?.user?.address) {
      setTimes();
    }
  }, [state?.user?.address]);
  useEffect(() => {
    if (localStorage.getItem("UserToken")) {
      setIsToken(true);
    } else {
      setIsToken(false);
    }
  }, [loading]);
  const api = useMemo(
    () => ({
      getWeb3,
      state,
      initState,
      nftItems,
      disconnect,
      updateNetWork,
      setState,
      userData,
      setUserData,
      userDataSponsor,
      setUserDataSponsor,
      followData,
      setFollowData,
      TOKEN_User,
      setTOKEN_User,
      loading,
      setLoading,
      event,
      setEvent,
      eventFillter,
      setEventFillter,
      perPage,
      setPerPage,
      page,
      setPage,
      pagination_options,
      setPagination_options,
      eventCategories,
      setEventCategories,
      CategoriesId,
      setCategoriesId,
      location,
      setLocation,
      date_From,
      setDate_From,
      date_To,
      setDate_To,
      keyword,
      setKeyword,
      history,
      setHistory,
      owned,
      setOwned,
      allNftFillter,
      setAllNftFillter,
      pagination_Options,
      setPagination_Options,
      inPage,
      setInPage,
      per_Page,
      setPer_Page,
      fillterKeyword,
      setFillterKeyword,
      statusNft,
      setStatusNft,
      price_to,
      setPrice_to,
      price_from,
      setPrice_from,
      condition,
      setCondition,
      CategoriesIdNft,
      setCategoriesIdNft,
      Nft_A_Section,
      setNft_A_Section,
      Nft_B_Section,
      setNft_B_Section,
      Nft_C_Section,
      setNft_C_Section,
      Nft_D_Section,
      setNft_D_Section,
      Nft_E_Section,
      setNft_E_Section,
      Nft_F_Section,
      setNft_F_Section,
      Nft_G_Section,
      setNft_G_Section,
      ownerOfNft,
      setOwnerOfNft,
      per_PageOwn,
      setPer_PageOwn,
      inPageOwn,
      setInPageOwn,
      pagination_OptionsOwn,
      setPagination_OptionsOwn,
      handleShow,
      handleClose,
      isNetwork,
      setIsNetwork,
      chainId,
      // wagmiClient,
      // chains,
      isToken,
      setIsToken,
      ethereum,
      isOwner,
      ownerLoading,
      reCallTime,
      setReCallTime,
      vestingStartingDate,
    }),
    [
      getWeb3,
      state,
      initState,
      nftItems,
      disconnect,
      updateNetWork,
      setState,
      userData,
      setUserData,
      userDataSponsor,
      setUserDataSponsor,
      followData,
      setFollowData,
      TOKEN_User,
      setTOKEN_User,
      loading,
      setLoading,
      event,
      setEvent,
      eventFillter,
      setEventFillter,
      perPage,
      setPerPage,
      page,
      setPage,
      pagination_options,
      setPagination_options,
      eventCategories,
      setEventCategories,
      CategoriesId,
      setCategoriesId,
      location,
      setLocation,
      date_From,
      setDate_From,
      date_To,
      setDate_To,
      keyword,
      setKeyword,
      history,
      setHistory,
      owned,
      setOwned,
      allNftFillter,
      setAllNftFillter,
      pagination_Options,
      setPagination_Options,
      inPage,
      setInPage,
      per_Page,
      setPer_Page,
      fillterKeyword,
      setFillterKeyword,
      statusNft,
      setStatusNft,
      price_to,
      setPrice_to,
      price_from,
      setPrice_from,
      condition,
      setCondition,
      CategoriesIdNft,
      setCategoriesIdNft,
      Nft_A_Section,
      setNft_A_Section,
      Nft_B_Section,
      setNft_B_Section,
      Nft_C_Section,
      setNft_C_Section,
      Nft_D_Section,
      setNft_D_Section,
      Nft_E_Section,
      setNft_E_Section,
      Nft_F_Section,
      setNft_F_Section,
      Nft_G_Section,
      setNft_G_Section,
      ownerOfNft,
      setOwnerOfNft,
      per_PageOwn,
      setPer_PageOwn,
      inPageOwn,
      setInPageOwn,
      pagination_OptionsOwn,
      setPagination_OptionsOwn,
      handleShow,
      handleClose,
      isNetwork,
      setIsNetwork,
      chainId,
      // wagmiClient,
      // chains,
      isToken,
      setIsToken,
      ethereum,
      isOwner,
      ownerLoading,
      reCallTime,
      setReCallTime,
      vestingStartingDate,
    ],
  );

  return (
    <AppContext.Provider value={api}>
      {/* <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          theme={darkTheme({
            accentColor: "#f8780c",
            accentColorForeground: "white",
            borderRadius: "large",
            fontStack: "system",
            overlayBlur: "small",
          })}
          coolMode
          chains={chains}
        > */}
      <ConnectWalletModal
        connectWalletModal={connectWalletModal}
        setConnectWalletModal={setConnectWalletModal}
      />
      {children}
      {/* </RainbowKitProvider>
      </WagmiConfig> */}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
