import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Layout/Footer";
import MainHeader from "../Layout/Header";
import Supply from "./Component/Supply";
import TabPages from "./Component/TabPages";

import Claim from "./Component/Revenues/Claim";
import "./style.css";
import { digitalShareTabs } from "../../constants";
/**
 * (Digital Share Page)
 * author Khaled Mofeed
 **/
function Index({ active }) {
  return (
    <Container fluid>
      <MainHeader />
      <Row className="justify-content-center DistributionVesting min-height-100per">
        <Col md={10} xl={10} className="mb-5">
          <Row>
            <Col xs={11} className="d-block mx-auto d-md-none mb-5">
              <Supply />
            </Col>
            <Col md={8} xl={8} className="mobile-size">
              <TabPages active={active} />
            </Col>
            <Col md={4} xl={4} className="d-none d-md-block">
              <Supply />
              {active === digitalShareTabs.REVENUE && <Claim />}
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer />
    </Container>
  );
}

export default Index;
