import React, { useState, useEffect } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Proposal from "../Component/Proposal";
import Revenues from "../Component/Revenues";
import { digitalShareTabs } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../configs/configNavLink";

function TabPages({ active }) {
  const [key, setKey] = useState(active);
  const navigate = useNavigate();

  /**
   * (Tab for view Proposal and Revenues Pages)
   * author Khaled Mofeed
   **/
  useEffect(() => {
    setKey(active);
  }, [active]);

  useEffect(() => {
    if (key === digitalShareTabs.PROPOSAL) {
      console.log("routes.DIGITAL_SHARE_PROPOSAL");
      navigate(routes.proposal);
    }
    if (key === digitalShareTabs.REVENUE) {
      console.log("routes.DIGITAL_SHARE_REVENUE");
      navigate(routes.revenues);
    }
  }, [key]);

  return (
    <>
      {/* <Tabs
        id="controlled-tab-example"
        defaultActiveKey={key}
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 tab-Pages"
      >
        <Tab
          eventKey={digitalShareTabs.PROPOSAL}
          title="Proposal"
          key="Proposal"
        > */}
      <Proposal />
      {/* </Tab> */}
      {/* <Tab eventKey={digitalShareTabs.REVENUE} title='Revenues' key='Revenues'>
          <Revenues />
        </Tab> */}
      {/* </Tabs> */}
    </>
  );
}

export default TabPages;
