import { ethers } from "ethers";
import abi from "../../../configs/abis/vesting-contract.json";
import config from "../../../configs";

const vestingAddress = config.get("smartContract.VESTING_ADDRESS");
// const proposalAddress = config.get('smartContract.PROPOSAL_ADDRESS');

export const makeContract = (signer) => {
  // await window.ethereum.request({ method: "eth_requestAccounts" });
  const contract = new ethers.Contract(vestingAddress, abi, signer);
  return contract;
};

// Helper functions

const valueParser = (value) => {
  return parseInt(value) / 10 ** 16;
};

const tableData = async (func, signer) => {
  const deployedPresaleContract = makeContract(signer);
  const rawData = await deployedPresaleContract[func]();
  const tableData = await Promise.all(
    rawData.map(async (item, index) => ({
      id: `${index}-${item.releaseDate}`,
      amount: valueParser(item.telvAmount),
      date: new Date(parseInt(item.releaseDate) * 1000).toLocaleString([], {
        hour12: false,
      }),
      status: item.status === 0 ? "Claimed" : item.status === 1 ? "Ready" : "Locked",
    })),
  );
  return tableData;
};

export const getAddress = async (signer) => {
  const address = await signer.getAddress();
  return address;
};

export const schedule = async (signer) => {
  const data = await tableData("schedual", signer);
  return data;
};

export const getTotalNumberVestingPeriods = async (signer) => {
  const contract = makeContract(signer);
  const value = await contract.getTotalNumberVestingPeriods();
  return valueParser(value);
};

export const getUserTotalOwned = async (signer) => {
  const contract = makeContract(signer);
  const value = await contract.getUserTotalOwned();
  return valueParser(value);
};

export const getUserTotalClaimed = async (signer) => {
  const contract = makeContract(signer);
  const value = await contract.getUserTotalClaimed();
  return valueParser(value);
};

export const getUserTotalAvailable = async (signer) => {
  const contract = makeContract(signer);
  console.log("contract", contract);
  const value = await contract.getUserTotalAvailable();
  console.log("value", value);
  return valueParser(value);
};

export const claimAvailable = async (signer) => {
  const contract = makeContract(signer);
  const tx = await contract.claimAvailable();
  await tx.wait();
  return tx;
};
