/* eslint-disable no-unused-vars */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.css';

function Claim() {
  return (
      <Col md={12} className=' d-flex flex-column claim-box revenue-bg'>
        <h1>READY TO CLAIM</h1>
        <hr />
          <div className='d-flex justify-content-between'>
            <span>Total Income</span>
            <span>8.056.8 TELV</span>
          </div>
          <hr />
          <div className='d-flex justify-content-between'>
            <span>Total Claimed</span>
            <span>7.056.8 TELV</span>
          </div>
        <div className='revenue-amount mt-4'>
            <p>Remaining Amount</p>
            <p>1,000.0 TELV</p>
        </div>
        <div>
          <span className='presale-submit-btn'>Claim</span>
        </div>
      </Col>
  );
}

export default Claim;
