import React from "react";
import usePagination from "../../../../hooks/usePagination";

function Pagenation({ setCurrentPage, currentPage, pages, totalList }) {
  const { currentThree, next, prev, jump } = usePagination({
    setCurrentPage,
    currentPage,
    maxPage: pages || 1,
  });

  const offset = 3;
  const curentShowing =
    currentPage * offset > totalList ? totalList : currentPage * offset;

  return (
    <div className="d-flex justify-content-between pagination">
      <div>
        <span className="page-number">
          Showing {curentShowing} of {totalList} data
        </span>
      </div>
      {pages > 1 ? (
        <div className="d-flex ">
          <span onClick={prev} className="pointerPagination">
            Previous
          </span>
          <ul>
            {currentThree.map((page) => (
              <li
                key={page}
                className={currentPage === page ? "active-page" : "page"}
                onClick={() => jump(page)}
              >
                {page}
              </li>
            ))}
          </ul>
          <span onClick={next} className="pointerPagination">
            Next
          </span>
        </div>
      ) : (
        <div className="d-flex disablePagination">
          <span>Previous</span>
          <ul>
            <li key="1" className="active-page">
              0
            </li>
          </ul>
          <span>Next</span>
        </div>
      )}
    </div>
  );
}

export default Pagenation;
