import React, { useContext } from "react";
import { Col, Row, Image } from "react-bootstrap";
import "./style.css";
import Metamask from "../../../assets/metamask.png";
import { AppContext } from "../../../scripts/context";
import { useEffect } from "react";
/*  This Component view when connect to wallet but not signatur to messase from metamask ,  (connect but not have token) | khaled Mofeed */
function Index({ openAccountModal, account }) {
  const { disconnect } = useContext(AppContext) || {};
  /*  This useEffect use for check if no account connect make disconnect | khaled Mofeed */
  useEffect(() => {
    if (!account) {
      disconnect();
    }
  }, [account]);

  useEffect(() => {
    // Add class to body when component mounts
    document.body.classList.add("WaitingForSignature");

    // Initialize class index and classNames array
    let classIndex = 0;
    const classNames = [
      "backgroundSlide1",
      "backgroundSlide2",
      "backgroundSlide3",
      "backgroundSlide4",
      "backgroundSlide5",
    ];

    // Function to update class name
    const updateClassName = () => {
      const currentClassName = classNames[classIndex];
      // Remove previous backgroundSlide class
      document.body.classList.remove(
        "backgroundSlide1",
        "backgroundSlide2",
        "backgroundSlide3",
        "backgroundSlide4",
        "backgroundSlide5"
      );
      // Add new class
      document.body.classList.add(currentClassName);
      // Increment class index
      classIndex = (classIndex + 1) % classNames.length;
    };

    // Interval to update class name every 3 seconds
    const intervalId = setInterval(updateClassName, 5000);

    // Clean up interval and remove class on component unmount
    return () => {
      document.body.classList.remove("WaitingForSignature");
      document.body.classList.remove(
        "backgroundSlide1",
        "backgroundSlide2",
        "backgroundSlide3",
        "backgroundSlide4"
      );
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array ensures this effect runs only once (on mount)
  return (
    <Row>
      <Col md={{ offset: 1, span: 10 }} className="text-left">
        <Row className="disc-wallet-row align-items-center">
          <Col
            xs={{ span: 12, order: "last" }}
            lg={{ span: 6, order: "first" }}
            md={{ span: 5, order: "first" }}
            className="wallet-connect-text"
          >
            <h4 className="titleConnection">Waiting For Signature</h4>
            <p className="pConnection">
              Please, Sign the message in MetaMask to confirm you own <br></br>{" "}
              this wallet address. This action will not cost any gas fees.
            </p>
            <span onClick={openAccountModal} className="text-center">
              <img
                src="/img/disconnectWhite.svg"
                alt="disconnect"
                className="disconnectImg"
              />{" "}
              Disconnect
            </span>
          </Col>
          <Col
            xs={12}
            lg={{ offset: 1, span: 5 }}
            md={5}
            className="text-center"
          >
            {/* <Image
              fluid
              className="metamask-img"
              src={Metamask}
              alt="Metamask"
            /> */}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Index;
