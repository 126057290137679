import React, { useEffect, useState, useContext } from "react";
import { Container, Image, Modal } from "react-bootstrap";
import MainHeader from "../Layout/Header";
import Footer from "../Layout/Footer";
import ComingSoon from "./ComingSoon";
import Live from "./Live";
import { AppContext } from "../../scripts/context";
import LoadPage from "./Live/components/LoadPage";
import RingImg from "../../assets/ring.jpeg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addGiveAway, checkIfUserExists } from "../../Helper/firebase";
import { useGetUserTotalPurchasedAmount } from "./presaleContractFunctions/wagmi";

const TO_GET_PRIZE = 33333 / 2;

const PrizeModal = ({ show, handleClose, address }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      shippingAddress: "",
      additionalInfo: "",
      option: "artistic_ceramic",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Full Name must be at least 3 characters")
        .required("Full Name is required"),
      phone: Yup.string().required("Phone Number is required"),
      shippingAddress: Yup.string()
        .min(10, "Shipping address must be at least 10 characters")
        .required("Shipping address is required"),
      additionalInfo: Yup.string().optional(),
      option: Yup.string().required("You must select an option"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      console.log(`Form submitted with values: ${JSON.stringify(values, null, 2)}`);
      addGiveAway({ ...values, address }).finally(() => {
        setLoading(false);
        handleClose();
      });
    },
  });

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      className='viewMain'
      show={show}
      onHide={handleClose}
      centered
      keyboard={false}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title>Claim your Rewards!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          As one of our esteemed community members, you have the exclusive opportunity to select
          your preferred smart ring from our elegant collection of three stunning colors. Don't miss
          out – claim your stylish smart ring now!
        </p>
        <p>
          The smart rings offer incredible benefits designed to enhance your daily life, including
          health management features like sleep tracking, temperature and heart rate monitoring, and
          SpO2 measurement. Additionally, enjoy intelligent life enhancements such as touch and
          gesture control for third-party apps and smart devices, key event reminders, and an SOS
          function for immediate assistance.
        </p>
        <Image src={RingImg} alt='Ring' className='w-100 h-auto' />
        <form className='form row position-relative' onSubmit={formik.handleSubmit}>
          <h6 className='nickName mt-4 mb-4'>Register Here for Shipment</h6>

          {/* Full Name */}
          <div className='form-group col-6 col-md-6'>
            <label htmlFor='id_nameSocial' className='basic-url'>
              Full Name
            </label>
            <input
              type='text'
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                formik.touched.name && formik.errors.name ? "is-invalid" : ""
              }`}
              name='name'
              id='id_nameSocial'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder='Enter your full name'
            />
            {formik.touched.name && formik.errors.name ? (
              <div className='invalid-feedback' id='name_error'>
                {formik.errors.name}
              </div>
            ) : null}
          </div>

          {/* Phone Number */}
          <div className='form-group col-6 col-md-6'>
            <label htmlFor='id_phone'>Phone Number</label>
            <input
              type='text'
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                formik.touched.phone && formik.errors.phone ? "is-invalid" : ""
              }`}
              name='phone'
              id='id_phone'
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder='Enter your phone number'
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className='invalid-feedback' id='phone_error'>
                {formik.errors.phone}
              </div>
            ) : null}
          </div>

          {/* Shipping Info */}
          <div className='form-group col-12 col-md-12'>
            <label htmlFor='id_shippingAddress'>Shipping Info.</label>
            <textarea
              name='shippingAddress'
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                formik.touched.shippingAddress && formik.errors.shippingAddress ? "is-invalid" : ""
              }`}
              rows='3'
              placeholder='include street address, city, state/province, postal/ZIP code, and country.'
              id='id_shippingAddress'
              value={formik.values.shippingAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            {formik.touched.shippingAddress && formik.errors.shippingAddress ? (
              <div className='invalid-feedback' id='shippingAddress_error'>
                {formik.errors.shippingAddress}
              </div>
            ) : null}
          </div>

          {/* Additional Info */}
          <div className='form-group col-12 col-md-12'>
            <label htmlFor='id_additionalInfo'>Additional Info.</label>
            <input
              type='text'
              className={`form-control form-control-solid border-0 bg-dark-2 ${
                formik.touched.additionalInfo && formik.errors.additionalInfo ? "is-invalid" : ""
              }`}
              name='additionalInfo'
              placeholder='Any additional info...'
              id='id_additionalInfo'
              value={formik.values.additionalInfo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.additionalInfo && formik.errors.additionalInfo ? (
              <div className='invalid-feedback' id='additionalInfo_error'>
                {formik.errors.additionalInfo}
              </div>
            ) : null}
          </div>

          {/* Radio Buttons */}
          <div className='form-group'>
            <h6 className='nickName mt-4 mb-4'>Ring Color Options:</h6>
            <label className='radio-label d-flex align-items-center'>
              <input
                type='radio'
                name='option'
                value='artistic_ceramic'
                className='radio-input custom-radio'
                checked={formik.values.option === "artistic_ceramic"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className='radio-custom artistic-ceramic '></span>
              Artistic Ceramic (Black)
            </label>
          </div>
          <div className='form-group'>
            <label className='radio-label align-items-center'>
              <input
                type='radio'
                name='option'
                value='full_metal'
                className='radio-input custom-radio'
                checked={formik.values.option === "full_metal"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className='radio-custom full-metal'></span>
              Full Metal (Rose Gold and Silver)
            </label>
          </div>
          <div className='form-group'>
            <label className='radio-label align-items-center'>
              <input
                type='radio'
                name='option'
                value='brilliant_diamond'
                className='radio-input custom-radio'
                checked={formik.values.option === "brilliant_diamond"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className='radio-custom brilliant-diamond'></span>
              Brilliant Diamond (Pink color studded with diamonds)
            </label>
            {formik.touched.option && formik.errors.option ? (
              <div className='invalid-feedback' id='option_error'>
                {formik.errors.option}
              </div>
            ) : null}
          </div>

          {/* Submit Button */}
          <div className='col-12 col-md-12'>
            <button
              id='info__submitUpdate'
              className='btn btn-primary btn-orange mr-2'
              type='submit'
              name='submit'
              value='Submit'
              disabled={loading}
            >
              Submit Your Info
              {loading && (
                <span
                  style={{ marginLeft: ".5rem" }}
                  className='spinner-grow spinner-grow-sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

function Index() {
  const {
    state: {
      contracts: {
        presale: {
          data: { time },
        },
      },
      user,
    } = {},
    reCallTime,
    setReCallTime,
  } = useContext(AppContext) || {};
  const statusObj = {
    // default: <DisconnectedWallet />,
    before: <ComingSoon />,
    live: <Live />,
    after: <Live />,
    load: <LoadPage />,
  };

  const { data: purchasedAmount } = useGetUserTotalPurchasedAmount();

  // this state is used to determine which component for render
  const [renderedComponent, setRenderedComponent] = useState(statusObj[time]);
  const [showModal, setShowModal] = useState("");

  useEffect(() => {
    if (purchasedAmount && user && user.address && user.wallet !== "") {
      (async () => {
        const userExists = await checkIfUserExists(user.address);
        if (!userExists && purchasedAmount > TO_GET_PRIZE) {
          setShowModal("prize");
        } else {
          setShowModal("welcome");
        }
      })();
    }
  }, [user]);

  useEffect(() => {
    if (!time) return;
    setRenderedComponent(statusObj[time]);
  }, [time, user]);
  useEffect(() => {
    if (renderedComponent === undefined) {
      setReCallTime(!reCallTime);
    }
  }, [renderedComponent]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <Container fluid>
      <MainHeader />
      {renderedComponent || <LoadPage />}
      <Footer />
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        className='viewMain'
        show={showModal === "welcome"}
        onHide={handleCloseModal}
        centered
        keyboard={false}
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Welcome to the AiTellit Members Area</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Congratulations on connecting your wallet! You're now just a few steps away from
            purchasing AiTellit tokens and becoming a part of our innovative community.
          </p>
          <p>Steps to Buy AiTellit Tokens:</p>
          <ul className='menuHome'>
            <li>Verify Your Account: Ensure your account details are up-to-date.</li>
            <li>Select Token Quantity: Decide how many tokens you wish to purchase.</li>
            <li>Confirm Transaction: Review your order and confirm the transaction.</li>
            <li>
              Secure Your Tokens: Your tokens will be in your wallet immediately, make sure they are
              secured!
            </li>
          </ul>
          <p>
            Enjoy exclusive access to cutting-edge AI solutions and community-driven projects. Thank
            you for joining AiTellit!
          </p>
          <p className='mb-0'>
            For support, contact us{" "}
            <a href='mailto:Info@aitellit.com' className='linkHere'>
              here
            </a>
            .
          </p>
        </Modal.Body>
      </Modal>
      <PrizeModal
        address={user?.address}
        show={showModal === "prize"}
        handleClose={handleCloseModal}
      />
    </Container>
  );
}

export default Index;
