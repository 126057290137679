import React, { useContext } from "react";
import { Col, Row, Image } from "react-bootstrap";
import "./style.css";
import Metamask from "../../../assets/metamask.png";
import { AppContext } from "../../../scripts/context";
import { useEffect } from "react";
import VideoPlayer from "./VideoPlayer";
/*  This Component view when disconnect to wallet | khaled Mofeed */
function Index({ openConnectModal, account, connected }) {
  const { state: { connectWallet } = {}, disconnect } =
    useContext(AppContext) || {};

  useEffect(() => {
    if (!account) {
      disconnect();
    }
  }, [account, connected]);

  useEffect(() => {
    // Add class to body when component mounts
    document.body.classList.add("ConnectToYourWallet");

    // Initialize class index and classNames array
    let classIndex = 0;
    const classNames = [
      "backgroundSlide1",
      "backgroundSlide2",
      "backgroundSlide3",
      "backgroundSlide4",
      "backgroundSlide5",
    ];

    // Function to update class name
    const updateClassName = () => {
      const currentClassName = classNames[classIndex];
      // Remove previous backgroundSlide class
      document.body.classList.remove(
        "backgroundSlide1",
        "backgroundSlide2",
        "backgroundSlide3",
        "backgroundSlide4",
        "backgroundSlide5"
      );
      // Add new class
      document.body.classList.add(currentClassName);
      // Increment class index
      classIndex = (classIndex + 1) % classNames.length;
    };

    // Interval to update class name every 3 seconds
    const intervalId = setInterval(updateClassName, 5000);

    // Clean up interval and remove class on component unmount
    return () => {
      document.body.classList.remove("ConnectToYourWallet");
      document.body.classList.remove(
        "backgroundSlide1",
        "backgroundSlide2",
        "backgroundSlide3",
        "backgroundSlide4",
        "backgroundSlide5"
      );
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array ensures this effect runs only once (on mount)
  return (
    <Row>
      <div class="mil-overlay"></div>
      <Col md={{ offset: 1, span: 10 }} className="text-center">
        <Row className="disc-wallet-row align-items-center">
          <Col
            xs={{ span: 12, order: "last" }}
            lg={{ span: 7, order: "first" }}
            md={{ span: 7, order: "first" }}
            className="wallet-connect-text"
          >
            {/* <h1>
              Connect To eee<br></br> Your Wallet
            </h1> */}
            {/* <span onClick={openConnectModal} className="text-center">
              Connect to a wallet
            </span> */}
            {/* <VideoPlayer /> */}

            <VideoPlayer />
          </Col>
          <Col xs={12} lg={5} md={5} className="text-left textVedio">
            {/* <Image
              fluid
              className="metamask-img"
              src={Metamask}
              alt="Metamask"
            /> */}

            <h4>Why You Should Buy AiTellit Tokens Today!</h4>

            <p>
              Imagine being part of a game-changing platform where Al meets a
              worldwide community of innovators. AiTellit is transforming
              technology by providing Al solutions, ethical advancements, and a
              self-governing network that promotes smart, connected
              interactions.
            </p>
            <p>
              Choosing AiTellit tokens let you tap into the world of Al and
              connect with a global network. Enjoy secure, user-friendly
              features, exclusive access to forums, news and support innovative
              Al projects. Benefit from potential growth, influence the
              platform's direction, and use tokens across various services.
              Trust our transparent operations and gain early participant perks.
              Join us to shape the future of technology today!
            </p>
            <span onClick={openConnectModal} className="conectTextLine">
              Connect your wallet and join the revolution!
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Index;
