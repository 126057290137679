import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { calculateTimeLeft } from "../../../../Helper/Helper";
import { useTimer } from "../../../../hooks/useTimer";
import "./style.css";

function Timer() {
  const { dateDifference, timeToCheck, getDeadlineDateHandler, setTimeLeft, timerComponents } =
    useTimer();
  useEffect(() => {
    getDeadlineDateHandler();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(dateDifference));
    }, timeToCheck);
    return () => clearTimeout(timer);
  });
  return (
    <Row>
      <Col lg={6} xl={5}>
        <Row>{timerComponents.length ? timerComponents : <span>Loading!</span>}</Row>
      </Col>
    </Row>
  );
}

export default Timer;
