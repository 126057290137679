import React from "react";
import { routes } from "../configs/configNavLink";
import Layout from "../pages/Layout";
import Home from "../pages/home";
import Token from "../pages/token";
import MarketPlace from "../pages/marketPlace";
import Profile from "../pages/profile";
import EditProfile from "../pages/profile/components/EditProfile";
import LoginAdmin from "../admin/Auth";
// import ResetPassword from "../admin/Auth/ResetPassword";
import { RequireAdminAuth } from "../admin/plugins/RequireAuth";
import UsersManagement from "../admin/pages/UsersManagement";
import RolesManagement from "../admin/pages/RolesManagement";
import ProfileAdmin from "../admin/pages/Profile";
import Sale from "../pages/presale";
import Vesting from "../pages/vesting";
import NftPresale from "../pages/digital-sharing";
import DigitalShare from "../pages/DigitalShare";
import { BallotProvider } from "../scripts/useBallot";
import { PresaleProvider } from "../scripts/usePresale";
import { NftPresaleProvider } from "../scripts/nftPresale";
import Map from "../pages/marketPlace/components/Map/";
import EventManagement from "../admin/pages/EventManagement";

import { digitalShareTabs } from "../constants";
import LandPage from "../pages/marketPlace/land";
import { ThemeProvider } from "@mui/material";
import theme from "../pages/marketPlace/land/theme";
import EditLand from "../pages/marketPlace/land/edit";
import Revenu from "../admin/pages/Revenue";
import Proposal from "../admin/pages/Proposal";
import LayoutWithOutConnect from "../pages/Layout/LayoutWithOutConnect";
import Admin from "../pages/admin";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    component: <LayoutWithOutConnect />,
    attributes: {
      path: routes.admin,
      id: "LayoutWithOutConnect",
    },
    children: [
      {
        header: "Admin",
        component: (
          <RequireAdminAuth>
            <Admin />
          </RequireAdminAuth>
        ),
        attributes: {
          path: routes.admin,
          id: "admin",
        },
      },
      {
        header: "login",
        component: <LoginAdmin />,
        attributes: {
          path: routes.loginAdmin,
          id: "loginAdmin",
        },
      },
    ],
  },
  {
    component: <Layout />,
    attributes: {
      path: "/",
      id: "Layout",
    },
    children: [
      // {
      //   header: "Map",
      //   component: <Map />,
      //   attributes: {
      //     path: routes.map,
      //     id: "Map",
      //   },
      // },
      {
        header: "Proposal",
        component: (
          <BallotProvider>
            <DigitalShare active={digitalShareTabs.PROPOSAL} />
          </BallotProvider>
        ),
        attributes: {
          path: routes.proposal,
          id: "proposal",
        },
      },
      // {
      //   header: "Revenues",
      //   component: (
      //     <BallotProvider>
      //       <DigitalShare active={digitalShareTabs.REVENUE} />
      //     </BallotProvider>
      //   ),
      //   attributes: {
      //     path: routes.revenues,
      //     id: "revenues",
      //   },
      // },
      // {
      //   header: "Events",
      //   component: <Events />,
      //   attributes: {
      //     path: routes.events,
      //     id: "Events",
      //   },
      // },
      // {
      //   header: "Submit Events",
      //   component: <SubmitEvent />,
      //   attributes: {
      //     path: routes.submitevents,
      //     id: "SubmitEvent",
      //   },
      // },
      {
        header: "Profile",
        component: <Profile />,
        attributes: {
          path: routes.profile,
          id: "Profile",
        },
      },
      // {
      //   header: "Edit Profile",
      //   component: <EditProfile />,
      //   attributes: {
      //     path: routes.editProfile,
      //     id: "EditProfile",
      //   },
      // },

      {
        header: "sale",
        component: (
          <PresaleProvider>
            <Sale />
          </PresaleProvider>
        ),
        attributes: {
          path: routes.sale,
          id: "sale",
        },
      },
      // {
      //   header: "vesting",
      //   component: <Vesting />,
      //   attributes: {
      //     path: routes.vesting,
      //     id: "vesting",
      //   },
      // },
      // {
      //   header: "Digital Share Sale",
      //   component: (
      //     <NftPresaleProvider>
      //       <NftPresale />
      //     </NftPresaleProvider>
      //   ),
      //   attributes: {
      //     path: routes.NftPresale,
      //     id: "nftPresale",
      //   },
      // },
      // {
      //   header: "Land Profile",
      //   component: (
      //     <ThemeProvider theme={theme}>
      //       <LandPage />
      //     </ThemeProvider>
      //   ),
      //   attributes: {
      //     path: routes.land,
      //     id: "land",
      //   },
      // },
      // {
      //   header: "Edit Land",
      //   component: (
      //     <ThemeProvider theme={theme}>
      //       <EditLand />
      //     </ThemeProvider>
      //   ),
      //   attributes: {
      //     path: routes.editLand,
      //     id: "land-edit",
      //   },
      // },
    ],
  },

  {
    component: <>Not Found</>,
    attributes: {
      path: "*",
      id: "notFound",
    },
  },
];
