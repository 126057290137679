import React from "react";
import { Col, Row } from "react-bootstrap";
import Timer from "./timer";
import "./style.css";
import ComingSoon from "./ComingSoon";

function ComingSoonPage(props) {
  return (
    <Row className='justify-content-md-center'>
      <Col md={10} className='bg-token-coming-soon'>
        <Row className='min-hight-80 justify-content-md-center align-self-center align-items-lg-center'>
          <Col md={10} className='align-lg-self-center'>
            <Row className='align-self-center'>
              <Col className='private-token'>
                <ComingSoon />
                <Timer {...props} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ComingSoonPage;
