const TOKEN = localStorage.getItem("admin-token");
export const adminConfig = {
  // api's base url
  baseURL: "https://api.aitellit.com/admin/api/v1",
  // baseURL: "http://localhost:4000/admin/api/v1",

  // requeired headers
  headers: {
    "Content-Type": "application/json",
    Authorization:
      TOKEN && TOKEN.includes("Bearer") ? TOKEN : `Bearer ${TOKEN}`,
  },
};
