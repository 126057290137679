import { Navigate } from "react-router-dom";
import { adminAxiosInstance } from "./axios";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

export const useAdminAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const TOKEN = localStorage.getItem("admin-token");
    if (TOKEN) {
      adminAxiosInstance
        .get("/verifyToken")
        .then((res) => {
          setUser(res.data);
          setLoading(false);
        })
        .catch((error) => {
          localStorage.removeItem("admin-token");
          console.log(error);
          setLoading(false);
        });
    } else {
      setLoading(false);
      toast.error("You have to sign in to access this page");
    }
  }, []);
  return { user, loading };
};

export const RequireAdminAuth = ({ children }) => {
  const { user, loading } = useAdminAuth();

  if (loading) {
    return (
      <div className='h-screen w-screen d-flex align-items-center justify-conetnt-center'>
        <Spinner animation='border' role='status' className='mx-auto' />
      </div>
    );
  } else if (!user) {
    return <Navigate to='/back-office/login' />;
  }

  return children;
};
