import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../configs/firebase.config";
import toast from "react-hot-toast";

const giveawaysCollectionRef = collection(db, "givaways");

export const addGiveAway = async (payload) => {
  try {
    await addDoc(giveawaysCollectionRef, payload);
    toast.success("You have been added to the reward program");
  } catch (error) {
    console.error("Error adding document: ", error);
    toast.error("Error adding you to the giveaway! Please try again");
  }
};

export const checkIfUserExists = async (address) => {
  const querySnapshot = await getDocs(giveawaysCollectionRef);
  let exists = false;
  querySnapshot.forEach((doc) => {
    if (doc.data().address === address) {
      exists = true;
    }
  });
  return exists;
};

export const getGiveAwayList = async () => {
  const querySnapshot = await getDocs(giveawaysCollectionRef);
  const data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}