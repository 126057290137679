import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../Layout/Footer";
import MainHeader from "../../Layout/Header";
import EditCoverProfile from "./EditCoverProfile";
import Head from "./Head";
import SideBarProfile from "./SideBarProfile";
import { useUploadImage } from "../Hooks/useUploadImage";
import NftMarketPlace from "./NftMarketPlace";
import InfoForm from "./EditProfile/components/InfoForm";

function ContainerPage(props) {
  const { userData } = useUploadImage("cover");

  return (
    <Container
      fluid
      className='bg-cover'
      style={{
        backgroundImage: `url(${userData?.cover})`,
      }}
    >
      <MainHeader />
      <Row className='justify-content-center DistributionVesting min-height-100per'>
        <Col md={12} xl={10} className='mb-5'>
          <Row>
            {/* the Left side bar profile components| khaled Mofeed   */}
            <Col md={12} xl={3} className=''>
              <SideBarProfile />
            </Col>
            {/* the Right side or the content space of profile components| khaled Mofeed   */}
            <Col md={12} xl={9} className='mobile-size'>
              <EditCoverProfile />
              <InfoForm />

              {/* <Head /> */}
              {/* <NftMarketPlace /> */}
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}

export default ContainerPage;
