import React from "react";
import "./style.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LoginForm from "./Login/LoginForm";

function LoginAdmin() {
  return (
    <section className='background-radial-gradient overflow-hidden'>
      <Container fluid className='px-4 py-5 px-md-5 text-center text-lg-start'>
        <Row className='justify-content-center'>
          <Col md={10} className='position-relative'>
            <div
              id='radius-shape-1'
              className='position-absolute rounded-circle shadow-5-strong'
            ></div>
            <div id='radius-shape-2' className='position-absolute shadow-5-strong'></div>
            <Row className='align-items-center justify-content-center min-height-100 align-items-center bg-border-gradiant contact-form-bg '>
              <Col md={10} lg={7} xl={5}>
                <div className='logo-admin'>
                  <img
                    src='/img/logo.png'
                    alt='logo admin'
                    className='logo-admin-img mb-5'
                    id='ID_logo_admin_img'
                  />
                </div>
                <div className='card bg-glass'>
                  <LoginForm />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default LoginAdmin;
