import React, { useEffect } from "react";
import { Col } from "react-bootstrap";

function UpdateNetWorkPage(openChainModal) {
  useEffect(() => {
    // Add class to body when component mounts
    document.body.classList.add("UpdateNetWorkPage");

    // Initialize class index and classNames array
    let classIndex = 0;
    const classNames = [
      "backgroundSlide1",
      "backgroundSlide2",
      "backgroundSlide3",
      "backgroundSlide4",
    ];

    // Function to update class name
    const updateClassName = () => {
      const currentClassName = classNames[classIndex];
      // Remove previous backgroundSlide class
      document.body.classList.remove(
        "backgroundSlide1",
        "backgroundSlide2",
        "backgroundSlide3",
        "backgroundSlide4"
      );
      // Add new class
      document.body.classList.add(currentClassName);
      // Increment class index
      classIndex = (classIndex + 1) % classNames.length;
    };

    // Interval to update class name every 3 seconds
    const intervalId = setInterval(updateClassName, 5000);

    // Clean up interval and remove class on component unmount
    return () => {
      document.body.classList.remove("UpdateNetWorkPage");
      document.body.classList.remove(
        "backgroundSlide1",
        "backgroundSlide2",
        "backgroundSlide3",
        "backgroundSlide4"
      );
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array ensures this effect runs only once (on mount)
  return (
    <>
      <h1 className="NetworkUpdate">Wrong Network</h1>
      <p className="mb-1 pt-2 text-left">
        You need to be connected to <b>Smart Chain - Testnet </b> to use this
        app, please switch your network to continue.
      </p>
    </>
  );
}

export default UpdateNetWorkPage;
