import { useEffect, useState } from "react";
import { calculateTimeLeft } from "../Helper/Helper";
import { Col } from "react-bootstrap";
import {
  useGetDeadlineDate,
  useGetStartingDate,
} from "../pages/presale/presaleContractFunctions/wagmi";

const timeToCheck = 1000;

export const useTimer = (type) => {
  const [date, setDate] = useState(0);
  const { parseValue: deadlineDate } = useGetDeadlineDate();
  const { parseValue: startingDate } = useGetStartingDate();

  let dateDifference = date - new Date();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(dateDifference));

  useEffect(() => {
    if (type === "live") {
      setDate(new Date(deadlineDate * 1000));
    } else if (type === "after") {
      setDate(new Date(startingDate * 1000));
    }
  }, [deadlineDate, startingDate, type]);

  const timerComponents = Object.keys(timeLeft).map((interval) => (
    <Col className='live-timers' xs={3} lg={3}>
      <div className='count'>
        <h1>{timeLeft[interval] < 10 ? `0${timeLeft[interval]}` : timeLeft[interval]}</h1>
        <p>{interval}</p>
      </div>
    </Col>
  ));

  return {
    dateDifference,
    timeToCheck,
    setTimeLeft,
    timerComponents,
    setDate,
  };
};
