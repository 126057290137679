import axios from "axios";
import { config } from "../../configs/axios";
import { adminConfig } from "../../configs/adminAxios";

/* Create a new axios instance | khaled Mofeed | 12/12/2022 */
const axiosInstance = axios.create(config);

export const adminAxiosInstance = axios.create(adminConfig);


export const request = (method, url, payload = {}) => {
  return axiosInstance[method](url, payload);
};
export default axiosInstance;
