import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCvd5VbAF9WKKNXFdI3ayAeEuqhDB2qGGQ",
  authDomain: "aitellit-da27d.firebaseapp.com",
  projectId: "aitellit-da27d",
  storageBucket: "aitellit-da27d.appspot.com",
  messagingSenderId: "1019095351432",
  appId: "1:1019095351432:web:dc86e9231bd7522d671f3d",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;