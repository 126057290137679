import { Spinner } from "react-bootstrap";
import { useForm } from "../../layouts/forms/actions";
import { useAuth } from "../../scripts/App/auth";
import { useNavigate } from "react-router-dom";
import { useAdminAuth } from "../../plugins/RequireAuth";

function LoginForm(props) {
  /**
   * use the useForm custom hook to handle the operations
   * of forms
   * author Khaled Mofeed
   */
  const { form, handleLoginSubmit, loading, setLoading, setValue, errors } = useForm({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const { user, loading: isAdminAuthenticating } = useAdminAuth();

  if (!isAdminAuthenticating && user) {
    navigate("/back-office");
  }

  const { email, password } = form;

  return (
    <>
      <h2 id='ID_Adminstration_Portal'>Adminstration Portal</h2>
      <form className='form row position-relative' onSubmit={handleLoginSubmit}>
        <div className='form-group col-12 col-md-12'>
          <input
            type='email'
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              errors.email && "is-invalid"
            }`}
            name='email'
            placeholder='Email address'
            value={email}
            onChange={setValue}
            id='ID_email_input'
          />

          {errors.email && (
            <div className='invalid-feedback' id='ID_feedback'>
              {errors.email}
            </div>
          )}
        </div>
        <div className='form-group col-12 col-md-12'>
          <input
            type='password'
            className={`form-control form-control-solid border-0 bg-dark-2 ${
              errors.password && "is-invalid"
            }`}
            name='password'
            placeholder='password'
            value={password}
            onChange={setValue}
            id='ID_password_input'
          />
          {errors.password && (
            <div className='invalid-feedback' id='ID_feedback2'>
              {errors.password}
            </div>
          )}
        </div>
        <div className='col-12 col-md-12 text-center'>
          <button
            type='submit'
            name='submit'
            value='Submit'
            disabled={loading}
            className='btn btn-lg btn-primary px-10'
            id='ID_submit'
          >
            login
            {loading && (
              <Spinner
                style={{ marginLeft: ".5rem" }}
                as='span'
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
                id='ID_Spinner'
              />
            )}
          </button>
        </div>
      </form>
    </>
  );
}

export default LoginForm;
