import React, { useState, useEffect } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import {
  schedule,
  getUserTotalAvailable,
  getUserTotalClaimed,
  getUserTotalOwned,
  claimAvailable,
} from "../../vestingContractFunctions";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { toast } from "react-hot-toast";
import { useDebounce } from "../../../../Helper/Helper";
import { useEthersSigner } from "../../../../hooks/useEthersSigner";
import { useReadContracts } from "wagmi";
import abi from "../../../../configs/abis/vesting-contract.json";
import config from "../../../../configs";

const vestingAddress = config.get("smartContract.VESTING_ADDRESS");

function BalanceDetails() {
  const signer = useEthersSigner();

  // own values
  const [progressPercent, setProgressPercent] = useState(0);

  // values from contract
  const [lastUnlockDate, setLastUnlockDate] = useState("");
  const [totalTELVAvailable, setTotalTELVAvailable] = useState(0);
  const [totalTELVClaimed, setTotalTELVClaimed] = useState(0);
  const [totalTELVOwned, setTotalTELVOwned] = useState(0);

  // refresh values from contract
  const [refresh, setRefresh] = useState(false);

  const { data, isFetched } = useReadContracts({
    contracts: ["schedual", "getUserTotalAvailable", "getUserTotalOwned", "getUserTotalClaimed"].map(
      (functionName) => ({
        abi,
        address: vestingAddress,
        functionName,
      }),
    ),
  });

  console.log("data", data);

  useEffect(() => {
    if (!signer) return;
    schedule(signer)
      .then((res) => {
        console.log("schedule", res);

        setLastUnlockDate(formatDate(res[res.length - 1].date));
      })
      .catch((error) => {
        console.log("schedule", JSON.parse(JSON.stringify(error)));
      });
    getUserTotalAvailable(signer)
      .then((res) => {
        console.log("getUserTotalAvailable", res);

        setTotalTELVAvailable(res);
      })
      .catch((error) => {
        console.log("getUserTotalAvailable", JSON.parse(JSON.stringify(error)));
      });
    getUserTotalClaimed(signer)
      .then((res) => {
        console.log("getUserTotalClaimed", res);

        setTotalTELVClaimed(res);
      })
      .catch((error) => {
        console.log("getUserTotalClaimed", JSON.parse(JSON.stringify(error)));
      });
    getUserTotalOwned(signer)
      .then((res) => {
        console.log("getUserTotalOwned", res);
        setTotalTELVOwned(res);
      })
      .catch((error) => {
        console.log("getUserTotalOwned", JSON.parse(JSON.stringify(error)));
      });
  }, [refresh, signer]);

  useEffect(() => {
    if (totalTELVClaimed && totalTELVOwned)
      setProgressPercent((totalTELVClaimed / totalTELVOwned) * 100);
  }, [totalTELVClaimed, totalTELVOwned]);

  // format date from 12/6/2022, 19:00:00 to 12.06.2022 19:00:00
  const formatDate = (date) => {
    const dateArray = date.split(",");
    const dateArray2 = dateArray[0].split("/").join(".");
    const newDate = `${dateArray2} ${dateArray[1]}`;
    return newDate;
  };

  const handleClaim = async (e) => {
    e.stopPropagation();
    if (!totalTELVAvailable) {
      toast.error("No AiT available to claim");
      return;
    }

    try {
      await claimAvailable();
      toast.success("Claimed successfully");
      setRefresh(!refresh);
    } catch (error) {
      toast.error("Claim failed! Please try again later.");
      console.log(error);
    }
  };

  // const debouncedHandleClaim = debounce(handleClaim, 1000);
  const debouncedHandleClaim = useDebounce(handleClaim, 1000);

  return (
    <>
      <h2 className='table-label mt-0'>You Own {totalTELVOwned} AiT</h2>
      <Row className='bg-balance-details align-items-center justify-content-center shared-margin shared-space '>
        <Col xs={10} className='live-buy-section'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='text-left'>
              <span className='text-muted'>Claimed</span>
              <p>{totalTELVClaimed} AiT</p>
            </div>

            <div>
              <div className='text-right'>
                <span className='text-muted'>Net Unlock:</span>
                <p>{lastUnlockDate}</p>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='text-left'>
              <span className='text-muted'>Of</span>
              <p>{totalTELVOwned} AiT</p>
            </div>
          </div>
          <div>
            <div className='pb-8'>
              <ProgressBar now={progressPercent} />
            </div>

            <span className='text-muted'>{Math.round(progressPercent)} %</span>
            <hr />
          </div>

          <div className='fees-data'>
            <div>
              <h6>Claimed</h6>
              <h2>{totalTELVClaimed}</h2>
            </div>
            <div>
              <h6>Ready to Claim</h6>
              <h2>{totalTELVAvailable}</h2>
            </div>
            <div>
              <h6>Locked Balance</h6>
              <h2>{totalTELVOwned - (totalTELVAvailable + totalTELVClaimed)}</h2>
            </div>
            <hr />
          </div>

          <div className='fees-data'>
            <div>
              <h6>Total Balance</h6>
              <h2>{totalTELVOwned}</h2>
            </div>
          </div>

          <div>
            <span onClick={debouncedHandleClaim} className='presale-submit-btn'>
              {totalTELVAvailable > 0 ? `Claim ${totalTELVAvailable} AiT` : "No AiT to Claim"}
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default BalanceDetails;
