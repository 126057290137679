import React from "react";
import { Row, Col } from "react-bootstrap";
import Buy from "./Buy";
import Rocket from "./Rocket";
import Share from "./Share";
import Purchased from "./Purchased";
import Affiliates from "./Affiliates";
import { usePresale } from "../../../../scripts/usePresale";
import { IoCopy, IoInformationCircleSharp } from "react-icons/io5";
import { BsQrCodeScan } from "react-icons/bs";
import { IconButton, Tooltip } from "@mui/material";

function LoadPage(props) {
  const { refetch } = usePresale();
  return (
    <Col className="live-presale-max-width mt-5">
      <Row className="justify-content-center ">
        <Col
          xs={12}
          sm={10}
          lg={6}
          className="d-flex flex-column justify-content-between"
        >
          <Row className="live-now-section shared-margin shared-space">
            <Col xs={{ offset: 1, span: 10 }} className="live-presale-now">
              <div className="live-presale-now-text">
                <h3>
                  Private Sale <span>is</span>
                </h3>
                <h1>LIVE NOW</h1>
              </div>
              <Row>
                <div class="col-lg-9">
                  <div class="row">
                    <div class="live-timers col-lg-3 col-3">
                      <div class="count">
                        <h1>--</h1>
                        <p>days</p>
                      </div>
                    </div>
                    <div class="live-timers col-lg-3 col-3">
                      <div class="count">
                        <h1>--</h1>
                        <p>hours</p>
                      </div>
                    </div>
                    <div class="live-timers col-lg-3 col-3">
                      <div class="count">
                        <h1>--</h1>
                        <p>minutes</p>
                      </div>
                    </div>
                    <div class="live-timers col-lg-3 col-3">
                      <div class="count">
                        <h1>--</h1>
                        <p>seconds</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="bg-live align-items-center justify-content-center shared-margin shared-space">
            <Col xs={10} className="live-buy-section live-buy-sectionCard">
              <Row className="first-row-in-share">
                <Col md={4}>
                  <h2>
                    Share <span className="min-telv">AiT</span>
                  </h2>
                  <p>private sale link</p>
                </Col>
                <Col md={8}>
                  <h2>
                    & Earn{" "}
                    <span className="purple-span">
                      {/* {parseInt(commission) * 10} */}
                      Up to 45%
                    </span>
                  </h2>
                  <p>of each affiliate’s purchase transact.</p>
                </Col>
              </Row>

              <div className="second-row-in-share">
                <div className="share-link">
                  <p>-------------------------------------- </p>
                </div>
                <div className="share-btns">
                  <>
                    <IoCopy className="cursor-pointer" />
                    <BsQrCodeScan className="BsQrCodeScan" />
                  </>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={10} lg={4}>
          <Row className="bg-live align-items-center justify-content-center shared-margin shared-space  h-100">
            <Col xs={10} className="live-buy-section ">
              <h1>Buy AiT Tokens</h1>
              <div className="bnb-address d-flex justify-content-between align-items-center">
                <p>0.0000 BNB</p>
                <div>
                  <span className="mr-2">xxxxx...xxxx</span>
                  <IoCopy className="cursor-pointer" />
                </div>
              </div>
              <div className="presal-amount">
                <h6 className="d-flex align-self-start">
                  Amount {"\u00A0"}
                  <span className="minAllo-span">{" AiT"}</span>
                </h6>
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <input
                    type="number"
                    // max={Number((balance / exchangeRate) * 100).toFixed(2) || 0}
                    // placeholder={"Min is: " + minimum}
                    placeholder="Enter Amount"
                    lang="en"
                    pattern="[0-9]*[.,]?[0-9]+"
                    className="setNFTHandlerInput"
                  />

                  <Tooltip
                    title={
                      "95% of the balance is displayed, with a buffer reserved for fees"
                    }
                    placement="top"
                    arrow
                  >
                    <span>MAX</span>
                  </Tooltip>
                </div>
                <hr />
              </div>
              <div className="fees-data">
                <div>
                  <h6>Exchange Rate</h6>

                  <h2>
                    <Tooltip placement="top" arrow>
                      <IconButton className="totalPriceH2"></IconButton>
                    </Tooltip>
                    0.0000 BNB/AiT
                  </h2>
                </div>
                <div>
                  <h6>
                    Deducted Amount{" "}
                    <Tooltip
                      title={
                        "This amount excludes any associated network fees."
                      }
                      placement="top"
                      arrow
                    >
                      <IconButton className="totalPriceH2">
                        <IoInformationCircleSharp />
                      </IconButton>
                    </Tooltip>
                  </h6>
                  <h2>
                    <Tooltip placement="top" arrow>
                      <IconButton className="totalPriceH2"></IconButton>
                    </Tooltip>
                    0.0000 BNB
                  </h2>
                </div>
              </div>
              <div class="mt-3">
                <span className="presale-submit-btn disabled" disabled>
                  Submit
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default LoadPage;
