/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Copy from "../../../../assets/copy-coins.svg";
import avatar from "../../../../assets/avatar.png";
import usePurchase from "../../../../scripts/usePresale";
import { copyToClipboard } from "../../../../Helper/Helper";
import toast from "react-hot-toast";
import "./style.css";
import { usePresale } from "../../../../scripts/usePresale";
import { EFFECT_TIME } from "../../../../constants";
import { AppContext } from "../../../../scripts/context";
import { Alert, IconButton, Tooltip } from "@mui/material";
import { IoInformationCircleSharp } from "react-icons/io5";
import { IoCopy } from "react-icons/io5";
import { request } from "../../../../admin/plugins/axios";
import { endpoints } from "../../../../utils/networks";

function Buy() {
  const {
    userData,
    state: { user = {}, getShortenAddress } = {},
    disconnect,
    userDataSponsor,
  } = useContext(AppContext) || {};
  function copyAdressToClipboard() {
    copyToClipboard(user.address);
  }
  const {
    address,
    exchangeRate,
    totalPrice,
    userPresaleData,
    balance,
    telv,
    error,
    setError,
    maxBuy,
    affiliate,
    setTelv,
    purchase,
    userTotalPurchasedAmount,
    minimum,
    success,
    setSuccess,
  } = usePresale();
  // to get my address
  const bnbAdressShort =
    address.substring(0, 6) + "..." + address.substring(address.length - 4);
  const affiliateShort = affiliate.slice(0, 6) + "..." + affiliate.slice(-4);
  const [userDataSponsorInfo, setUserDataSponsorInfo] = useState(null);
  const getProfileDataSponsorInfo = async () => {
    try {
      const response = await request(
        endpoints.ProfileData.GetProfileData.method,
        `${endpoints.ProfileData.GetProfileData.url}?wallet_address=${userPresaleData?.sponsor_}`
      );
      setUserDataSponsorInfo(response?.data?.data?.user); // Assuming the response contains name and avatar
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  // console.log("userDataSponsorInfo>>>>>>>", userDataSponsorInfo);
  useEffect(() => {
    if (
      userPresaleData?.sponsor_ !==
        "0x0000000000000000000000000000000000000000" ||
      userPresaleData?.sponsor_ !== undefined
    ) {
      getProfileDataSponsorInfo();
    }
  }, [userPresaleData?.sponsor_]);

  // console.log("userPresaleData", userPresaleData);
  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    return () => {
      setTimeout(() => {
        toast.dismiss();
      }, EFFECT_TIME);
    };
  }, [error]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => {
      setTimeout(() => {
        toast.dismiss();
      }, EFFECT_TIME);
    };
  }, [success]);

  const purchaseHandler = async (e) => {
    await purchase(e);
  };

  return (
    <Row className="bg-live align-items-center justify-content-center shared-margin shared-space  h-100">
      <Col xs={10} className="live-buy-section ">
        <h1>Buy AiT Tokens</h1>
        <div className="bnb-address d-flex justify-content-between align-items-center">
          <p>{Number(balance).toFixed(4)} BNB</p>
          <div>
            <span className="mr-2">{bnbAdressShort}</span>
            <IoCopy
              className="cursor-pointer"
              onClick={copyAdressToClipboard}
            />

            {/* <img
              onClick={copyAdressToClipboard}
              src={Copy}
              alt="copy"
              className="cursor-pointer"
            /> */}
          </div>
        </div>
        <div className="presal-amount">
          <h6 className="d-flex align-self-start">
            Amount {"\u00A0"}
            <span className="minAllo-span">
              {userTotalPurchasedAmount > 0 &&
                userTotalPurchasedAmount + " AiT"}
            </span>
          </h6>
          <div className="d-flex justify-content-between align-items-center pb-2">
            <input
              type="number"
              value={telv}
              onChange={(e) => setTelv(e.target.value)}
              min={0}
              max={maxBuy || 0}
              // max={Number((balance / exchangeRate) * 100).toFixed(2) || 0}
              // placeholder={"Min is: " + minimum}
              placeholder="Enter Amount"
              lang="en"
              pattern="[0-9]*[.,]?[0-9]+"
              className="setNFTHandlerInput"
            />

            <Tooltip
              title={
                "95% of the balance is displayed, with a buffer reserved for fees"
              }
              placement="top"
              arrow
            >
              <span
                onClick={() => {
                  setTelv(
                    ((balance * 0.95) / (totalPrice / 10 ** 18)).toFixed(2)
                  );
                }}
              >
                MAX
              </span>
            </Tooltip>
          </div>
          <hr />
        </div>
        {minimum > 0 && (
          <Alert
            variant="outlined"
            severity="warning"
            className="outlinedWarn bnbAlertH2"
          >
            Minimum Purchase amount is $50 ={" "}
            <Tooltip title={minimum} placement="top" arrow>
              <IconButton className="AlertH2">{minimum}</IconButton>
            </Tooltip>
            AiT
          </Alert>
        )}

        <div className="fees-data">
          <div>
            <h6>Exchange Rate</h6>

            <h2>
              <Tooltip title={totalPrice / 10 ** 18} placement="top" arrow>
                <IconButton className="totalPriceH2">
                  {(totalPrice / 10 ** 18).toFixed(6)}
                </IconButton>
              </Tooltip>
              BNB/AiT
            </h2>
          </div>
          <div>
            <h6>
              Deducted Amount{" "}
              <Tooltip
                title={"This amount excludes any associated network fees."}
                placement="top"
                arrow
              >
                <IconButton className="totalPriceH2">
                  <IoInformationCircleSharp />
                </IconButton>
              </Tooltip>
            </h6>
            <h2>
              <Tooltip
                title={(telv * totalPrice) / 10 ** 18}
                placement="top"
                arrow
              >
                <IconButton className="totalPriceH2">
                  {((telv * totalPrice) / 10 ** 18).toFixed(6)}
                </IconButton>
              </Tooltip>
              BNB
            </h2>
          </div>
          {/* {console.log(
            "userPresaleData?.sponsor_",
            userPresaleData?.sponsor_,
            "userDataSponsor?.wallet_address",
            userDataSponsor?.wallet_address,
            "affiliate",
            affiliateShort
          )} */}

          {/* userPresaleData هذا الي بيرجع من الكونتراكت وبيرجع قيمة الادرس مشان نفحص اذا الو اب او لا  */}

          {/* userDataSponsor هذا الي بيرجع من الاي بي اي لما اعطيه السبونسر الي موجود باللينك فوق وبيرجع فيه بيانات الادرس هادا  */}

          {/* userDataSponsorInfo  هذا الي بيرجع بيانات الادرس السبونسر الاب       */}

          {/* case when you have sponsor for you */}
          {userPresaleData?.sponsor_ !==
            "0x0000000000000000000000000000000000000000" &&
            userDataSponsorInfo?.wallet_address && (
              <div className="bnb-address-sponsor d-flex justify-content-between align-items-center">
                <h6>Sponsor Address </h6>

                <div className="bnb-address-sponsor-content">
                  <span className="mr-2">
                    {userDataSponsorInfo?.wallet_address?.slice(0, 6) +
                      "..." +
                      userDataSponsorInfo?.wallet_address?.slice(-4)}
                  </span>
                  {userDataSponsorInfo?.avatar ? (
                    <>
                      <Tooltip
                        title={userDataSponsorInfo?.name}
                        placement="top"
                        arrow
                      >
                        <IconButton className="totalPriceH2">
                          <img
                            src={userDataSponsorInfo?.avatar}
                            alt={userDataSponsorInfo?.name}
                            className="avatarRespons"
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <img
                      src={avatar}
                      alt="avatarRespons"
                      className="avatarRespons"
                    />
                  )}
                </div>
              </div>
            )}

          {/* case when you have link of sponsor and you dont have sponsor */}
          {(userPresaleData?.sponsor_ ===
            "0x0000000000000000000000000000000000000000" ||
            userPresaleData?.sponsor_ === undefined) &&
            (affiliate || userDataSponsor?.wallet_address) &&
            userDataSponsor?.wallet_address !== userData?.wallet_address && (
              <div className="bnb-address-sponsor d-flex justify-content-between align-items-center">
                <h6>Sponsor Address </h6>

                <div className="bnb-address-sponsor-content">
                  <span className="mr-2">
                    {affiliate
                      ? affiliateShort
                      : userDataSponsor?.wallet_address.slice(0, 6) +
                        "..." +
                        userDataSponsor?.wallet_address.slice(-4)}
                  </span>
                  {userDataSponsor?.avatar ? (
                    <>
                      <Tooltip
                        title={userDataSponsor?.name}
                        placement="top"
                        arrow
                      >
                        <IconButton className="totalPriceH2">
                          <img
                            src={userDataSponsor?.avatar}
                            alt={userDataSponsor?.name}
                            className="avatarRespons"
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <img
                      src={avatar}
                      alt="avatarRespons"
                      className="avatarRespons"
                    />
                  )}
                </div>
              </div>
            )}

          {/* case when you have link of sponsor and you can't have another sponsor */}
          {userPresaleData?.sponsor_ !==
            "0x0000000000000000000000000000000000000000" &&
            userDataSponsor?.wallet_address && (
              <Alert
                variant="outlined"
                severity="warning"
                className="outlinedWarn2 bnbAlertH3"
              >
                You have a sponsor , and you can't have another one
              </Alert>
            )}
          {/* case when you have your link of sponsor and You can't be your own sponsor */}
          {userDataSponsor?.wallet_address === userData?.wallet_address && (
            <Alert
              variant="outlined"
              severity="warning"
              className="outlinedWarn3 bnbAlertH3"
            >
              You can't be your own sponsor
            </Alert>
          )}
          {/* <div>
            <h6>Sponsor Address</h6>

            <img
              src={userDataSponsor?.avatar}
              alt={userDataSponsor?.name}
              className="avatarRespons"
            />
            <h2>
              {affiliate
                ? affiliateShort
                : userDataSponsor?.wallet_address
                ? userDataSponsor?.wallet_address.slice(0, 6) +
                  "..." +
                  userDataSponsor?.wallet_address.slice(-4)
                : "N/A"}
            </h2>
          </div> */}
        </div>
        <div class="mt-3">
          {userDataSponsor?.wallet_address === userData?.wallet_address ? (
            <span className="presale-submit-btn disabled" disabled>
              Submit
            </span>
          ) : (
            <span onClick={purchaseHandler} className="presale-submit-btn">
              Submit
            </span>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default Buy;
