import { routes } from "../../../../configs/configNavLink";
import { TiHomeOutline } from "react-icons/ti";

const Routes = [
  // {
  //   header: "Marketplace",
  //   link: routes.marketplace,
  //   id: "Marketplace",
  //   target: true,
  // },
  // {
  //   header: "Digital Share",
  //   children: [
  //     {
  //       header: "Digital Share Sale",
  //       link: routes.NftPresale,
  //       id: "sale",
  //     },
  //     {
  //       header: "Proposal",
  //       link: routes.proposal,
  //       id: "digitalShare",
  //     },
  //     {
  //       header: "Revenues",
  //       link: routes.revenues,
  //       id: "Revenues",
  //     },
  //   ],
  // },
  // {
  //   header: "Events",
  //   link: routes.events,
  //   id: "Events",
  // },
  // {
  //   header: "TELV Presale",
  //   link: routes.sale,
  //   id: "TELPresale",
  // },
  // {
  //   header: "Proposal",
  //   link: routes.proposal,
  //   id: "digitalShare",
  // },
 
  {
    header: <TiHomeOutline />,
    link: routes.home,
    id: "home",
  },
  // {
  //   header: "Token",
  //   children: [
  //     {
  //       header: "Token",
  //       link: routes.token,
  //       id: "Token",
  //     },
  //     {
  //       header: "Vesting",
  //       link: routes.vesting,
  //       id: "vesting",
  //     },
  //     {
  //       header: "TELV Presale",
  //       link: routes.sale,
  //       id: "TELPresale",
  //     },
  //   ],
  // },
  // {
  //   header: "Whitepaper",
  //   link: routes.whitepaper,
  //   id: "Whitepaper",
  // },
];
export default Routes;
