import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

export const pxToRem = (px) => {
  return `${px / 16}rem`;
};

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#e34038",
      dark: "#f56861",
      contrastText: "#FCFCFD",
    },
    success: {
      main: "#72D98D",
    },
    error: {
      main: "#FF5B5B",
    },
    secondary: {
      main: "#5cb937",
    },
    background: {
      paper: "#3B3B3B",
      default: "#2b2b2b",
    },
  },
  typography: {
    h1: {
      fontSize: "4rem",
      lineHeight: 1,
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.8125rem",
      fontWeight: 700,
      lineHeight: 1,
    },
    h3: {
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: 1.4,
    },
    h4: {
      fontSize: "2rem",
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: 1.66,
    },
    h6: {
      fontSize: "1.125rem",
      lineHeight: 1.5,
      fontWeight: 600,
    },
    h7: {
      fontSize: "1rem",
      lineHeight: "48px",
      fontWeight: 600,
      letterSpacing: "-0.01rem",
    },
    body1: {
      fontSize: "2rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "1.75rem",
      fontWeight: 400,
      lineHeight: 1.14,
    },
    body3: {
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: 1.55,
    },
    body4: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body5: { fontSize: "0.875rem", fontWeight: 400, lineHeight: "24px" },
    body6: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 2,
    },
    body7: {
      fontSize: "0.625rem",
      fontWeight: 400,
      lineHeight: 2.4,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        containedSizeSmall: {
          fontSize: "0.875rem",
          lineHeight: "24px",
          // height: "29px",
          padding: "2.55px 8.51px",
          borderRadius: "100px",
          fontWeight: 400,
        },

        containedSizeLarge: {
          fontSize: "1rem",
          lineHeight: "20px",
          // height: "29px",
          padding: "14px 14px",
          borderRadius: "100px",
          fontWeight: 500,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          // ":checked":  "#fff",
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        componentsProps: {
          typography: {
            variant: "body5",
            color: "#ced3df",
          },
        },
      },
    },
  },
});

export default theme;
