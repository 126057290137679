import { useContext } from "react";
import { AppContext } from "../scripts/context";
import WalletConnectProvider from "@walletconnect/web3-provider";
import QRCodeModal from "@walletconnect/qrcode-modal";
import toast from "react-hot-toast";
import { SaveLocalStorag } from "../Helper/Helper";
import config from "../configs";
/*  This custome hook for Connect Wallet by ConnectWallet | khaled Mofeed | 5/12/2022 */
export function useConnectWallet({ setConnectWalletModal }) {
  const chainId = config.get("smartContract.CHAIN_ID");
  const chainRpcUrl = config.get("smartContract.CHAIN_RPC_URL");
  /*  import state, getWeb3, setState and initState from context AppContext | khaled Mofeed | 5/12/2022 */
  const { state, getWeb3, setState, initState } = useContext(AppContext);
  /*  function for submit Connect wallet by WalletConnect | khaled Mofeed | 5/12/2022 */
  const submitConnectWalletConnect = async () => {
    /*  function for close modal Connect wallet by WalletConnect | khaled Mofeed | 5/12/2022 */
    setConnectWalletModal(false);
    /*  define provider by WalletConnectProvider and set Define params to be requested by the helper library and I used a file (.env.local) to define some variables .. to maintain the security of this information| khaled Mofeed | 5/12/2022 */
    const provider = new WalletConnectProvider({
      qrcodeModal: QRCodeModal,
      qrcode: true,
      rpc: {
        [chainId]: chainRpcUrl,
      },
      bridge: "https://bridge.walletconnect.org",
      qrcodeModalOptions: {
        desktopLinks: ["metamask"],
      },
    });
    /*  after check if provider.chainId is matched , setState , set disconnect and method and set getWeb3 , SaveLocalStorag | khaled Mofeed | 5/12/2022 */
    await provider.enable();
    if (provider.chainId !== Number(chainId)) {
      setState({
        loading: false,
        netWork: {
          ...state.netWork,
          chainId: provider.chainId,
        },
      });
      await provider.disconnect();

      toast.error("Wrong network");
      return;
    }

    setState({ loading: true });
    getWeb3(provider.accounts[0], provider);
    SaveLocalStorag("connectStatus", 2);
    provider?.on("accountsChanged", (accounts) => {
      if (state.user.address !== accounts[0]) {
        setState({ loading: true });
        getWeb3(accounts[0], provider);
      }
    });

    provider?.on("chainChanged", async (userChainId) => {
      if (userChainId !== Number(chainId)) {
        await provider.disconnect();
      }
    });

    provider?.on("disconnect", () => {
      setState({
        ...initState,
        loading: false,
      });
      SaveLocalStorag("connectStatus", 0);
    });
  };
  return submitConnectWalletConnect;
}
