import React, { useEffect, useState } from "react";
import CustomTable from "../../../../components/CustomTable";
import Pagination from "./Pagenation";
import { getUserCommissionsList } from "../../presaleContractFunctions";
import "./style.css";
import { useEthersSigner } from "../../../../hooks/useEthersSigner";

function Purchased() {
  const signer = useEthersSigner();

  // current page to the affiliate table
  const [currentPage, setCurrentPage] = useState(1);
  const [tableTotalList, setTableTotalList] = useState(0);
  const [pages, setPages] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0); // New state for total commission

  // take the total list and return the list of the current page to the table
  const affiliateList = async (limit) => {
    if (limit <= 0) limit = 1;
    const { transactions, totalList } = await getUserCommissionsList(
      currentPage - 1,
      limit,
      signer
    );
    setTableTotalList(totalList);
    setPages(Math.ceil(totalList / limit) || 1);

    return transactions;
  };
  useEffect(() => {
    setTotalCommission(totalCommission);
  }, [totalCommission]);
  return (
    signer && (
      <div className='shared-space'>
      <div className="CommissionsDetails">
        <h2 className="table-label">Affiliate Commissions</h2>
        <h2 className="table-label totalCommission">
          Total Commissions = {totalCommission.toFixed(6)} BNB
        </h2>
        <div className="borderSpace"></div>
      </div>
        <CustomTable
          cb={affiliateList}
          currentPage={currentPage}
          no={3}
          affiliateList={true}
          totalCommission={totalCommission}
          setTotalCommission={setTotalCommission}
          totalList={tableTotalList}
        />
        <Pagination
          totalList={tableTotalList}
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    )
  );
}

export default Purchased;
