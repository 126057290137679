import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { calculateTimeLeft } from "../../../../Helper/Helper";
import { useTimer } from "../../../../hooks/useTimer";
import "./style.css";

function Rocket() {
  const { dateDifference, timeToCheck, setTimeLeft, timerComponents } =
    useTimer("live");

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(dateDifference));
    }, timeToCheck);
    return () => clearTimeout(timer);
  });

  return (
    <Row className='live-now-section shared-margin shared-space'>
      <Col xs={{ offset: 1, span: 10 }} className='live-presale-now'>
        <div className='live-presale-now-text'>
          <h3>
            Private Sale <span>is</span>
          </h3>
          <h1>LIVE NOW</h1>
        </div>
        <Row>
          <Col lg={9}>
            <Row>
              {timerComponents.length ? (
                timerComponents
              ) : (
                <>
                  <div class='live-timers col-lg-3 col-3'>
                    <div class='count'>
                      <h1>--</h1>
                      <p>days</p>
                    </div>
                  </div>
                  <div class='live-timers col-lg-3 col-3'>
                    <div class='count'>
                      <h1>--</h1>
                      <p>hours</p>
                    </div>
                  </div>
                  <div class='live-timers col-lg-3 col-3'>
                    <div class='count'>
                      <h1>--</h1>
                      <p>minutes</p>
                    </div>
                  </div>
                  <div class='live-timers col-lg-3 col-3'>
                    <div class='count'>
                      <h1>--</h1>
                      <p>seconds</p>
                    </div>
                  </div>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Rocket;
