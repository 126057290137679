import React from "react";
import { styled } from "@mui/material/styles";

import { InputBase } from "@mui/material";

const StyledNumberInput = styled(InputBase)(() => ({
  border: "1px solid #333",
  borderRadius: ".5rem",
  maxHeight: "unset",
  padding: "11px 8px",
  maxWidth: "92px",
  fontSize: "16px",
  textAlign: "center",
  color: "#ced3df",
  backgroundColor: "#545454",
  "& input": {
    fontSize: "12px",
    textAlign: "center",
  },
}));

export default StyledNumberInput;
