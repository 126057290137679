export const routes = {
  // marketplace: "/marketplace",
  // map: "/map",
  // news: "/news",
  // events: "/events",
  // submitevents: "/submit-events",
  // token: "/token",
  // whitepaper: "/whitepaper",
  // home: "",
  sale: "",
  home: "https://aitellit.com/",
  LitePaper: "/WhitePaper.pdf",
  vesting: "/vesting",
  proposal: "/proposal",
  // revenues: "/revenues",
  profile: "/profile",
  editProfile: "/edit-Profile",
  // NftPresale: "/digital-share/sale",
  // loginAdmin: "/back-office/login",
  // resetPasswordAdmin: "/back-office/reset-password",
  // usersManagementAdmin: "/back-office/users-management",
  // RolesManagementAdmin: "/back-office/RolesManagement",
  // RevenueManagementAdmin: "/back-office/revenue",
  // eventManagementAdmin: "/back-office/event-management",
  // proposalManagementAdmin: "/back-office/proposal-management",
  // profileAdmin: "/back-office/profile",
  admin: "/back-office",
  loginAdmin: "/back-office/login",

  // land: "/land/:id",
  // editLand: "/land/:id/edit",
};
