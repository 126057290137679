import React, { useState } from "react";
import CustomTable from "../../../../components/CustomTable";
import Pagination from "./Pagenation";
import "./style.css";
import { getUserPurchasedTransactionsList } from "../../presaleContractFunctions";
import { useEthersSigner } from "../../../../hooks/useEthersSigner";

function Purchased() {
  const signer = useEthersSigner();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(0);

  const [tableTotalList, setTableTotalList] = useState(0);
  const [pages, setPages] = useState(0);

  const transactionList = async (limit) => {
    const { transactions, totalList } = await getUserPurchasedTransactionsList(
      currentPage - 1,
      limit,
      signer
    );
    setTableTotalList(totalList);
    setPages(Math.ceil(totalList / limit) || 1);
    return transactions;
  };

  return (
    signer && (
      <div className='shared-space'>
        <h2 className='table-label'>AiT Purchased</h2>
        <CustomTable cb={transactionList} currentPage={currentPage} no={3} totalList={tableTotalList} />
        <Pagination
          totalList={tableTotalList}
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    )
  );
}

export default Purchased;
